import React, { useEffect, useState } from "react";
import { getAllPdfs } from "../../utils";
import { Link } from "react-router-dom";
import { Button, PDFListRow, VideoVelocityTypePopup, DeckVelocityTypePopup } from "../index";

const PDFList = ({toggleShowProcessingOverlay}) => {
  // first fetch, with loader
  const [pdfs, setPdfs] = useState([]);
  useEffect(() => {
    (async () => {
      const pdfs = await getAllPdfs();
      setPdfs(pdfs);
    })();
  }, []);

  const toggleShowProcessingOverlayNext = (data)=>{
    toggleShowProcessingOverlay(data);
  }


  // polling without loader
  useEffect(() => {
    const myInterval = setInterval(fetchQuote, 5000);

    return () => {
      clearInterval(myInterval);
    };
  }, []);

  const fetchQuote = async () => {
    const pdfs = await getAllPdfs(false);
    setPdfs(pdfs);
  };

  const [showVideoVelocityTypePopup, setShowVideoVelocityTypePopup] = useState(false);
  const [velocityPdfId, setVelocityPdfId] = useState('');
  const toggleShowVelocityTypePopup = (id) => {
    setVelocityPdfId(id)
    setShowVideoVelocityTypePopup((prev) => !prev);
  };

  const [showDeckVelocityTypePopup, setShowDeckVelocityTypePopup] = useState(false);
  const toggleShowDeckVelocityTypePopup = (id) => {
    setVelocityPdfId(id)
    setShowDeckVelocityTypePopup((prev) => !prev);
  };
  

  return (
    <div className="overflow-x-auto">
      { showVideoVelocityTypePopup &&
        <VideoVelocityTypePopup pdfId={velocityPdfId} toggleOverlay={toggleShowVelocityTypePopup}/>
      }
      { showDeckVelocityTypePopup &&
        <DeckVelocityTypePopup pdfId={velocityPdfId} toggleOverlay={toggleShowDeckVelocityTypePopup}/>
      }
      <table className="table-auto min-w-full divide-y divide-gray-200">
        <thead>
          <tr className="bg-gray-50 text-left">
            <th
              scope="col"
              className="px-6 py-3 font-bold uppercase tracking-wider text-xl"
            >
              Title
            </th>
            {/* <th
              scope="col"
              className="px-6 py-3 font-bold uppercase tracking-wider text-xl"
            >
              Domain
            </th> */}

            <th
              scope="col"
              className="px-6 py-3 font-bold uppercase tracking-wider text-xl"
            >
              PDF Processing Status
            </th>

            <th
              scope="col"
              className="px-6 py-3 font-bold uppercase tracking-wider text-xl"
            >
              Interactive Document
            </th>

            <th
              scope="col"
              className="px-6 py-3 font-bold uppercase tracking-wider text-xl"
            >
              Video
            </th>

            <th
              scope="col"
              className="px-6 py-3 font-bold uppercase tracking-wider text-xl"
            >
              Generate Deck
            </th>

            <th
              scope="col"
              className="px-6 py-3 font-bold uppercase tracking-wider text-xl"
            >
              Outputs
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {pdfs.map((ele) => (
            <PDFListRow pdfItem={ele} toggleShowProcessingOverlayNext={toggleShowProcessingOverlayNext} showVelocityTypePopup={toggleShowVelocityTypePopup} showDeckVelocityTypePopup={toggleShowDeckVelocityTypePopup}/>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export { PDFList };
