import * as pdfjsLib from "pdfjs-dist/legacy/build/pdf.js";
import $ from "jquery";
import { getSelectionContent } from "./utils";

pdfjsLib.GlobalWorkerOptions.workerSrc =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js";

// Make constants to avid stringly typed code
const EVT_TERMINOLOGY_MORE_INFO_CLICKED = "TERMINOLOGY_MORE_INFO_CLICKED";
const EVT_PAGE_CHANGED = "PAGE_CHANGED";
const TEXT_SELECTION_CONTEXT_MENU_ITEM_CLICKED =
  "TEXT_SELECTION_CONTEXT_MENU_ITEM_CLICKED";
const TEXT_SELECTION_CONTEXT_MENU_SHOWN = "TEXT_SELECTION_CONTEXT_MENU_SHOWN";
const AREA_SELECTION_CONTEXT_MENU_ITEM_CLICKED =
  "AREA_SELECTION_CONTEXT_MENU_ITEM_CLICKED";

const sHTML = `
<div id="pdf-frame">
  <div id="pdf-container" class="no-scrollbar">
    <div id="pdf-canvas-container">
      <div id="pdf-content">
        <canvas id="pdf-canvas" ></canvas>
        <div id="pdf-text-wrapper">
          <div id="pdf-tooltip">
            <div id="pdf-tooltip-text">
              <div id="pdf-tooltip-text-content">
              </div>
              <div>&nbsp;</div>
              <a id="pdf-tooltip-link"><b>Learn more...</b></a>
            </div>
          </div>

          <div id="pdf-annotation-container">
            <div id="pdf-annotation-text">
            </div>
          </div>
          
          <div id="pdf-sel-box"></div>
          <div id="pdf-menu"><div id="pdf-menu-items">Hello world</div></div>
          
          <div id="pdf-text" >
            
          </div>
        </div>
      </div>
    </div>
    <div id="pdf-scroller">
    </div>
  </div>
</div>
`;

const sHTMLLoader = `
<svg 
  style="margin: auto; margin-top: 20%; background: none; display: block; shape-rendering: auto;" 
  width="96px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
  <circle cx="50" cy="50" r="16" stroke-width="4" stroke="rgb(10,10,10)" stroke-dasharray="80 20" fill="none">
    <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
  </circle>
</svg>
`;

const sStyles = `
  /* This is the outermost element that fills the available space */ 
  #pdf-frame
  {
      display: flex;
      flex-direction: column;
      height: 100%;
  }

  /* 
  This is the container that holds the content and will provide a scrollbar
  It contains the pdf PDF area and a scroller element
  The PDF are is sized exactly to the viewport and is sticky to the top
  The scroller element is sized to the entire PDF document, so that this element gets a scrollbar
  The PDF area renders the viewport of the PDF document based on the scroll position
  */
  #pdf-container
  {
      height: 100%;
      overflow: scroll;
      display: flex;
      align-items: stretch;
      background: lightgray;
  }
  
  /* This defines the PDF area set sticky so that the scrollbar never moves it*/
  #pdf-canvas-container
  {
      width: 100%;
      margin: 0px auto 0px auto;
      position: sticky;
      top: 0px;
      overflow-x: auto;
      overflow-y: hidden;
  }
  
  /* 
    This container is positioned at the top of the PDF area and houses the canvas and the text layer 
    It is sized to the visible viewport area
  */
  #pdf-content
  {
      position: absolute;
      width: 100%;
      height: 100%;
      line-height: 1;
  }

  /* PDF content style, canvas and text share this, but we cant use a class */
  #pdf-text-wrapper
  {
      position: absolute;
      width: 100%;
      height: 100%;
  }
  
  #pdf-text
  {
    cursor: text;
  }
  
  #pdf-canvas
  {
      position: absolute;
      width: 100%;
      height: 100%;
  }
  
  .pdf-text-span 
  { 
    position: absolute; 
    transform-origin: 0 0; 
    color: transparent; 
    contain:content; 
    white-space: nowrap;
    box-sizing: border-box;
    display: inline-block;
   }
   
   t-x
   {
     border-bottom: 2px dotted black;
     display: inline-block;
     cursor : pointer;
   }

    h-x,h-x:hover
   {  
    background-color: #41c1eb52;
    display: inline-block;
    cursor : pointer;
   }

   .publication-link{
    display: inline-block;
    text-decoration: underline;
    cursor : pointer;
    border-bottom: 2px solid blue;
  }
  .publication-link:hover{
    background-color:#2472e085;
  }

  .reference-link{
    display: inline-block;
    text-decoration: underline;
    cursor : pointer;
    border-bottom: 2px solid black;
  }
  .reference-link:hover{
    background-color:#2472e085;
  }
  
  .pdf-text-span::selection, t-x::selection, .publication-link::selection,.reference-link::selection
  { 
    color: transparent; 
    background: #00FF0030; 
  }
  h-x::selection{
    background-color: #41c1eb52;
  }

  
  #pdf-empty 
  {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  
  .pdf-text-span-highlight:
  {
    background: #ffff0050;
  }
     
  #pdf-tooltip
  {
    z-index: 2000;
    position: fixed;
    opacity: 0;
    transition: opacity 0.5s;
    visibility: hidden;
  }
  
  #pdf-tooltip-text
  {
    background: #ebf4fc;
    border: 1px solid #1D1D1D;
    border-radius: 5px;
    font-size: 16px;
    padding: 10px;
    font-family: sans-serif;
    color: #1D1D1D;
    display: flex;
    flex-direction: column;
  }
  
  #pdf-tooltip-link
  {
    color: #000000;
    font-size: 14px;
    align-self: flex-end;
    cursor: pointer;
    text-decoration: underline;
  }
  
  #pdf-tooltip-text-content
  {
    flex-grow: 1;
    line-height: 25px;
  }

  #pdf-annotation-container{
    z-index: 2000;
    position: fixed;
    opacity: 0;
    transition: opacity 0.5s;
    visibility: hidden;
    background: #ebf4fc;
    padding: 10px;
    border-radius: 8px;
  }

  #pdf-annotation-text
  {
    flex-grow: 1;
    line-height: 25px;
  }
  
  #pdf-sel-box 
  {
    position: fixed;
    border: 2px solid #00000040;
    background: #00ff0010;
    z-index: 1000;
    display: none;
  }
  
  #pdf-menu
  {
    z-index: 1000;
    position: fixed;
    opacity: 0;
    transition: opacity 0.5s;
    visibility: hidden;
  }
  
  #pdf-menu-items
  {
    background: #ebf4fc;
    border-radius: 5px;
    color: #1D1D1D;
    display: flex;
    flex-direction: row
  }
  
  .pdf-menu-item
  {
    padding: 8px;
    cursor: pointer;
    border-radius: 3px;
  }
  
  .pdf-menu-item:hover
  {
    background: #82c4ff;
    text-decoration: none;
  }
  
  .pdf-menu-item-separator
  {
    min-width: 1px;
    background: #00000050;
  }
`;

function safeRegEx(s) {
  try {
    return new RegExp(s, "gi");
  } catch (e) {
    console.log(e);
  }
  return null;
}

// Smooth scroll renderer for PDF
export default class PDFView {
  fPage = 0; // Page number as a float allowing fractional page scroll
  nCached = 6; // Number of rendered pages to keep in cache

  dctPages = {}; // Cache of pages current page +/- nCached/2 - Map of page number to page object
  dctCanvas = {}; // Each entry contains [canvas, canvas-context, TextContent]
  ctxMain = null; // The main visible canvas context
  dctRegex = {};

  dctPDFViewPort = null;
  elemCanvas = null; // actual canvas
  elemContent = null; // parent div of canvas that also houses the floating text spans
  elemStyle = null; // style element for the floating text spans we need to add dynamically

  elemText = null; // text layer
  elemTooltip = null; // tooltip element
  elemTooltipText = null; // tooltip text area

  elemAnnotationContainer = null;
  elemAnnotationText = null;

  fScale = 1;
  pxOffscreenW = 0; // Offscreen canvas sizes
  pxOffscreenH = 0;

  pxViewportW = 0; // Dimensions of visible rendered area
  pxViewPortH = 0;

  pxPageH = 0; // Height of a complete page wrt the visible viewport
  // Width would be the same as pxViewportW

  pxCanvasW = 0; // Pixel dimensions (internal) of the view canvas
  pxCanvasH = 0;

  pxScrollableH = 0; // Height of the scrollable area

  iRenderedPage = -1; // Last rendered page number (0 indexed)
  nZoom = 100; // percentage zoom applied by simply making the canvas width that many percent of its parents width
  // 100% fits the width exactly. > 100 causes a horizontal scrollbar

  dpiRatio = devicePixelRatio * 2; // 2x creates smoothest rendering

  dctPhrases = {}; // Map of key phrase to hints
  dctPhraseIDX = {}; // Map of key phrase to initial data index
  dctIdxPhrase = {};

  arrPhraseByLength = []; // List of phrases sorted by length reverse
  arrMatches = {}; // Map of page number to list of phrase matches as [beg, end, phrase-index]
  dctCitations = {}; // Map of citation paper number to phrase index in arrPhraseByLength

  arrIdxSpan = []; // Span index for each character in the text layer
  arrSpanIdx = []; // Char range for each span

  arrRegex = []; // List of regexes to search for

  rcSelectBounds = null; // Bounds of the current selection
  sOriginalSelectText = null; //Original text with hyphens and spaces
  sSelectText = null; // Text of the current selection => cleaned up version

  rcLTPage = null; // Page number where the event started
  rcRBPage = null; // Page number where the event ended

  referencePage = Number.MAX_SAFE_INTEGER; //Assume that the reference section is present in the last page and ...
  referenceBlock = Number.MAX_SAFE_INTEGER;

  // List of publications
  publicationsList = [];
  publicationMatches = [];

  referencesList = [];
  referencesMatches = [];

  highlightsList = [];
  highlightsMatches = [];

  highlightsData = [
    {
      id: "fYhdYHWr4DURT0KqPiG4Q",
      text: "The cardiovascular safety of testosterone-replacement therapy in middle-aged and older men with hypogonadism has not been determine",
      annotation: "this is background section",
      regex:
        "TheW*cardiovascularW*safetyW*ofW*testosteroneW*replacementW*therapyW*inW*middleW*agedW*andW*olderW*menW*withW*hypogonadismW*hasW*notW*beenW*determine",
    },
    {
      id: "ZypbTAXHmVZ0XYwMwcfXw",
      text: "blind, placebo-controlled, noninferiority trial, we enrolled 5246 men 45 to 80 years of age who had preexisting or a high risk of cardiovascular disease and who reported symptoms of hypogonadism and had two fasting testosterone levels of less than 30",
      annotation: "This is methods section",
    },
  ];

  onEvent = (msg, data) => {}; // Callback for events that happen on the page

  // The "Learn more.." link handler
  onTooltipLinkClick = () => {
    // Get the phrase, page and bbox for this match, and trigger event for the parent
    let idxHighlight = this.elemTooltip.getAttribute("data-id");
    const phrase = this.arrPhraseByLength[this.arrMatches[idxHighlight][2]];
    const idxOrg = this.dctPhraseIDX[phrase];
    const data = {
      term: this.termData[idxOrg].term,
      _id: this.termData[idxOrg]._id,
      bbox: this.rcSelectBounds,
      page: this.fPage | 0,
    };

    this.onEvent(EVT_TERMINOLOGY_MORE_INFO_CLICKED, data);
  };

  getPageData = () => {
    let iPage = this.fPage | 0;
    let fFrac = this.fPage - iPage;
    return {
      pageH: this.pxPageH,
      pageW: this.pxViewportW,
      pxOffscreenW: this.pxOffscreenW,
      pxOffscreenH: this.pxOffscreenH,
      pxCanvasW: this.pxCanvasW,
      pxCanvasH: this.pxCanvasH,
      fFrac,
      fromPageTop: this.pxPageH * fFrac,
      // pxViewPortH: this.pxViewPortH,
      // pxViewPortW: this.pxViewPortW,
    };
  };

  // On mouseup we check handle text selection if any
  onMouseUp = (e) => {
    try{
    // Ignore right/middle click
    if (e.button !== 0) return;

    // If we have collapsed selections clear and quit
    const sel = window.getSelection();
    // if (sel.isCollapsed) {
    //   this.doClearSelection();
    //   return;
    // }

    // Get the bounds for the selection and the text container
    const range = sel.getRangeAt(0);
    const rcSelect = range.getBoundingClientRect();
    const rcText = this.elemContainer.getBoundingClientRect();

    // If the width is too small or goes outside the text container, clear and quit
    if (
      rcSelect.width < 10 // Comment on the below conditions as 2 page paragraph were not getting selected
      // rcSelect.width < 10 ||
      // rcSelect.left < rcText.left ||
      // rcSelect.right > rcText.right ||
      // rcSelect.top < rcText.top ||
      // rcSelect.bottom > rcText.bottom
    ) {
      this.doClearSelection();
      return;
    }

    // First expand this selection rectangle by 4px on all sides
    // const nExpand = 4;
    // const bx = rcSelect.left - nExpand;
    // const by = rcSelect.top - nExpand;
    // const bw = rcSelect.width + nExpand * 2;
    // const bh = rcSelect.height + nExpand * 2;
    //
    // // Set the selection indication box to this size to show the user what they have selected
    // this.elemSelBox.style.left = bx + 'px';
    // this.elemSelBox.style.width = bw + 'px';
    // this.elemSelBox.style.top = by + 'px';
    // this.elemSelBox.style.height = bh + 'px';
    // this.elemSelBox.style.display = 'block';

    // Calculate the pdf viewport bbox and save for later
    this.rcSelectBounds = this.xfContentRCToPdf(rcSelect);

    // Save selected text for later
    this.sOriginalSelectText = window.getSelection().toString();
    this.sSelectText = getSelectionContent();

    this.onRightClick(e);

    // Get rid of the selection
    //window.getSelection().removeAllRanges();

    /* * / For debugging
    const [xx,yy,ww,hh] = this.xfPdfToCanvas(x,y,w,h);
    this.ctxMain.fillStyle = '#ff000030';
    this.ctxMain.fillRect(xx,yy,ww,hh);
    /* */
  }catch(err){
    console.log(err)
  }
  };

  // Right click handler for the selection box
  onRightClick = (e) => {
    // Show the menu, such that it doesnt cross the edges
    //e.preventDefault();
    // hide the annotation,if any, before showing menuBar
    this.doShowElement(this.elemAnnotationContainer, false);
    this.doShowElement(this.elemMenu, true);
    const rcText = this.elemText.getBoundingClientRect();
    const rcMenu = this.elemMenu.getBoundingClientRect();

    const xMid = rcText.left + rcText.width / 2;
    const yMid = rcText.top + rcText.height / 2;

    // If its on the left of center, show it on the right else left
    this.elemMenu.style.left =
      e.clientX < xMid ? e.clientX + "px" : e.clientX - rcMenu.width + "px";

    // If its on the top of center, show it on the bottom else top
    this.elemMenu.style.top =
      e.clientY < yMid ? e.clientY + "px" : e.clientY - rcMenu.height + "px";

    // Send message to show custom menu items:
    this.setMenuItems([]);
    this.onEvent(TEXT_SELECTION_CONTEXT_MENU_SHOWN, {
      bbox: this.rcSelectBounds,
      originalText: this.sOriginalSelectText,
      text: this.sSelectText,
    });
    // this.onEvent(AREA_SELECTION_CONTEXT_MENU_ITEM_CLICKED, {
    //   key: "My value",
    //   bbox: [1, 2, 3, 4],
    // });
  };

  // On Menu item click, trigger event for the parent
  onMenuItemClick = (e) => {
    // console.log({ e });
    this.doHideMenu();
    console.log("TEXT CONTENT : ", {
      idx: e.target.getAttribute("data-id"),
      item: e.target.textContent,
      bbox: this.rcSelectBounds,
      originalText: this.sOriginalSelectText,
      text: this.sSelectText,
      lt_page: (this.rcLTPage | 0) + 1,
      x: e.clientX,
      y: e.clientY,
    });
    setTimeout(
      () =>
        this.onEvent(TEXT_SELECTION_CONTEXT_MENU_ITEM_CLICKED, {
          idx: e.target.getAttribute("data-id"),
          item: e.target.textContent,
          bbox: this.rcSelectBounds,
          originalText: this.sOriginalSelectText,
          text: this.sSelectText,
          lt_page: (this.rcLTPage | 0) + 1,
          x: e.clientX,
          y: e.clientY,
        }),
      0
    );
  };

  onTextHoverIn = (e) => {
    // Dont show tooltip if we are selecting text
    if (window.getSelection().rangeCount) return;

    // First de-highlight everything to handle mouse over edge cases
    this.doHighLight(-1, false);

    // Get the element that was hovered over, and any match id it has
    let elemSpan = e.target;
    let idxHighlight = elemSpan.getAttribute("data-match");

    // Highlight all the elements with this match id
    this.doHighLight(idxHighlight, true);

    // Defer showing the tooltip by a small delay, but mark the element that was hovered over
    this.idxHighlight = idxHighlight;
    setTimeout(() => this.showToolTip(elemSpan, idxHighlight), 200);
  };

  onTextHoverOut = (e) => {
    this.idxHighlight = -1;

    // Check if we are outside the tooltip, if so de-highlight and hide tooltip
    const rcTooltip = this.elemTooltip.getBoundingClientRect();
    if (
      e.clientX < rcTooltip.left ||
      e.clientX > rcTooltip.right ||
      e.clientY < rcTooltip.top ||
      e.clientY > rcTooltip.bottom
    ) {
      this.doShowElement(this.elemTooltip, false);

      // De-highlight all the elements with this match id
      let elemSpan = e.target;
      let idxHighlight = elemSpan.getAttribute("data-match");
      this.doHighLight(idxHighlight, false);
    }
  };

  highlightLink = (idxHighlight, bHighlight) => {
    let elems =
      idxHighlight === -1 ? $("[link-id]") : $(`[link-id="${idxHighlight}"]`);
    for (const k of elems) {
      k.style.backgroundColor = bHighlight ? "#00FF0050" : "transparent";
    }
  };

  showHighlightToolTip = (elem, idx) => {
    console.log( elem);
    this.showAnnotatioNote(elem, idx);
  };

  onReferenceHoverIn = (e) => {
    // this.highlightLink = -1;

    // Get the known match id from the tooltip
    let linkId = e.target.getAttribute("link-id");

    // De-highlight all the elements with this match id
    this.highlightLink(linkId, true);
  };

  onReferenceHoverOut = (e) => {
    let linkId = e.target.getAttribute("link-id");

    // De-highlight all the elements with this match id
    this.highlightLink(linkId, false);
  };

  onHighlightHoverIn = (e) => {
    let highlightId = e.target.getAttribute("highlight-id");
    let elemSpan = e.target;
    setTimeout(() => this.showHighlightToolTip(elemSpan, highlightId), 200);
    // this.showHighlightToolTip(elemSpan, highlightId);
    // De-highlight all the elements with this match id
    // this.highlightLink(linkId, true);
  };

  onHighlightHoverOut = (e) => {
    let highlightId = e.target.getAttribute("highlight-id");
    let elemSpan = e.target;
    console.log("OUT");
    // this.showHighlightToolTip(elemSpan, highlightId);
    setTimeout(
      () => this.doShowElement(this.elemAnnotationContainer, false),
      200
    );
  };

  onTooltipHoverOut = (e) => {
    this.idxHighlight = -1;

    // Get the known match id from the tooltip
    let idxHighlight = this.elemTooltip.getAttribute("data-id");

    // De-highlight all the elements with this match id
    this.doHighLight(idxHighlight, false);

    // Hide the tooltip
    this.doShowElement(this.elemTooltip, false);
  };

  doHideMenu = () => this.doShowElement(this.elemMenu, false);

  doShowElement = (e, show) => {
    e.style.visibility = show ? "visible" : "hidden";
    e.style.opacity = show ? 1 : 0;
  };

  doClearSelection = () => {
    // PDF text layer hack
    // Get rid of all the <br> elements, which mess up selection behavior
    document.querySelectorAll("#pdf-text > br").forEach((e) => e.remove());

    //this.elemSelBox.style.display = 'none';
    if (window.getSelection) {
      window.getSelection().removeAllRanges();
    }
    this.doHideMenu();
  };

  onCopy = () => {
    const selectedContent = getSelectionContent();
    navigator.clipboard.writeText(selectedContent);
  };

  // Builds the DOM structure for the PDF viewer on the fly
  mount(elemRoot) {
    console.log("Mounting..........");
    window.p = this;
    const children = Array.from(elemRoot.childNodes); // Convert NodeList to an array
    elemRoot.innerHTML = sHTML;
    children.forEach((child) => {
      elemRoot.appendChild(child); // Append each child node individually
    });

    this.elemCanvas = $("#pdf-canvas")[0];
    this.elemContent = $("#pdf-content")[0];
    this.elemScroller = $("#pdf-scroller")[0];
    this.elemContainer = $("#pdf-container")[0];
    this.elemText = $("#pdf-text")[0];
    this.elemTooltip = $("#pdf-tooltip")[0];
    this.elemTooltipText = $("#pdf-tooltip-text")[0];
    this.elemTooltipTextContent = $("#pdf-tooltip-text-content")[0];
    this.elemTooltipLink = $("#pdf-tooltip-link")[0];
    this.elemAnnotationContainer = $("#pdf-annotation-container")[0];
    this.elemAnnotationText = $("#pdf-annotation-text")[0];

    this.elemSelBox = $("#pdf-sel-box")[0];
    this.elemMenu = $("#pdf-menu")[0];
    this.elemMenuItems = $("#pdf-menu-items")[0];
    // $("#pdf-menu").forEach((ele) => this.doShowElement(ele));
    console.log("Existing ", { elemMenu: this.elemMenu });

    // Add a scroll handler for the scroll container and resize handler for outer framew
    this.elemContainer.addEventListener("scroll", this.onScroll);

    this.elemContainer.addEventListener("mouseup", this.onMouseUp);

    this.elemContainer.addEventListener("copy", this.onCopy);

    window.addEventListener("resize", this.onResize);

    // Add a click handler for the "Learn more" link
    this.elemTooltipLink.addEventListener("click", this.onTooltipLinkClick);

    // Add right click handler for the document
    //document.onclick = this.doHideMenu;
    //this.elemSelBox.oncontextmenu = this.onRightClick;

    // Get the canvas context and set the props we need
    this.elemCanvas.width = this.elemCanvas.clientWidth;
    this.elemCanvas.height = this.elemCanvas.clientHeight;

    this.ctxMain = this.elemCanvas.getContext("2d", { alpha: false });

    this.ctxMain.imageSmoothingEnabled = false;
    this.ctxMain.lineWidth = 1;
    this.ctxMain.strokeStyle = "#00000010";
    this.ctxMain.fillStyle = "white";
    this.ctxMain.fillRect(0, 0, this.elemCanvas.width, this.elemCanvas.height);
    this.ctxMain.strokeRect(
      0,
      0,
      this.elemCanvas.width,
      this.elemCanvas.height
    );

    // Check for a style element with id pdf-viewer in the document head and create one if not found
    this.elemStyle = $("#pdf-viewer-style")[0];
    if (!this.elemStyle) {
      this.elemStyle = document.createElement("style");
      this.elemStyle.id = "pdf-viewer-style";
      this.elemStyle.innerHTML = sStyles;
      this.elemStyle.appendChild(document.createTextNode(sStyles));
      document.head.appendChild(this.elemStyle);
    }
  }

  doPageChange = () =>
    this.onEvent(EVT_PAGE_CHANGED, {
      nPage: (this.fPage | 0) + 1,
      nPages: this.nPages,
    });

  // Called after the PDF has been loaded from URL or file
  onLoadPDF = (pdf) => {
    this.pdf = pdf;
    this.fPage = 0;
    this.nPages = this.pdf.numPages;
    this.doPageChange();
  };

  // Load the PDF and phrases from the given URLs
  doLoadResources = async (sPDFUrl, sPhrasesUrl) => {
    this.elemText.innerHTML = sHTMLLoader;

    console.log("---------------------Loading resources---------------------");
    const pdf = await pdfjsLib.getDocument(sPDFUrl).promise;
    this.onLoadPDF(pdf);

    if (sPhrasesUrl) {
      const r = await fetch(sPhrasesUrl);
      console.log("---------------------Loaded phrases---------------------", {
        r,
      });

      const j = await r.json();
      console.log("after conversion : ", { j });
      this.loadTerms(j);
      console.log("-------------------before rendering-------------");
    }
    this.iRenderedPage = -1;
    this.dctPages = {};
    this.dctCanvas = {};

    console.log("---------------------Rendering---------------------");
    await this.render(true);
  };

  // TO not highlight keywords present there
  setReferenceSection(pageNum, blockId) {
    this.referencePage = pageNum;
    this.referenceBlock = blockId;
  }

  setPublicationsList(publications) {
    this.publicationsList = publications;
  }
  setReferencesList(references) {
    this.referencesList = references;
  }

  setHighlightsList(highlights) {
    this.highlightsList = highlights;
  }

  // Called by parent to set context menu items
  setMenuItems = (aItems) => {
    this.elemMenuItems.innerHTML = "";
    aItems.forEach((sItem, i) => {
      if (i) {
        const elemSep = document.createElement("div");
        elemSep.classList.add("pdf-menu-item-separator");
        this.elemMenuItems.appendChild(elemSep);
      }

      const elemItem = document.createElement("div");
      elemItem.classList.add("pdf-menu-item");
      elemItem.setAttribute("data-id", i);
      elemItem.innerHTML = sItem;
      elemItem.onclick = this.onMenuItemClick;
      this.elemMenuItems.appendChild(elemItem);
    });
  };

  loadURL(sUrl) {
    return new Promise((resolve) => {
      pdfjsLib.getDocument(sUrl).promise.then((pdf) => {
        this.onLoadPDF(pdf);
        resolve(true);
      });
    });
  }

  loadFile(sFile) {
    if (sFile) {
      const fileReader = new FileReader();
      const viewer = this;

      fileReader.onload = function () {
        if (this.result) {
          const arrData = new Uint8Array(this.result);
          const loadingTask = pdfjsLib.getDocument(arrData);
          loadingTask.promise.then(viewer.onLoadPDF);
        }
      };

      //Step 3:Read the file as ArrayBuffer
      fileReader.readAsArrayBuffer(sFile);
    }
  }

  // Load the phrases for the current page
  loadTerms = (arr) => {
    this.termData = arr;

    this.dctPhrases = {};

    let i = 0;
    for (let dctPhrase of arr) {
      this.dctPhrases[dctPhrase.term] = dctPhrase.definition;
      this.dctPhraseIDX[dctPhrase.term] = i;
      this.dctRegex[dctPhrase.term] = dctPhrase.regex;
      // this.arrRegex.push(dctPhrase.regex);
      ++i;
    }

    console.log("dctPhrases : ", {
      dctPhrases: this.dctPhrases,
      dctPhraseIDX: this.dctPhraseIDX,
      dctIdxPhrase: this.dctIdxPhrase,
    });
    this.arrPhraseByLength = Object.keys(this.dctPhrases);
    this.arrPhraseByLength.sort((a, b) => b.length - a.length);
    console.log("arrPhraseByLength : ", this.arrPhraseByLength);

    // Convert all the phrases to regexes
    for (let i = 0; i < this.arrPhraseByLength.length; ++i) {
      const term = this.arrPhraseByLength[i];

      this.arrRegex.push(safeRegEx(this.dctRegex[term]));
    }
    console.log("arrRegex : ", this.arrRegex);
  };

  getPhraseRegExStr = (sPhrase) => {
    // Eats up as many sChomp as possible from s and returns the remaining string
    function chomp(s, sChomp) {
      const l = sChomp.length;
      while (s.indexOf(sChomp) === 0) s = s.substr(l);
      return s;
    }

    const sNonWordStarMarker = "\0";
    const rxNonWordPlus = /[\s`"';.,:-]+/gi;

    // Create the regex from the phrase
    const isShort = sPhrase.length < 5;

    // Replace one or more non-word chars to match 0 or more non-word chars
    let sRegex = sPhrase.trim().replace(rxNonWordPlus, sNonWordStarMarker);

    // Remove leading non-word matches from the regex, ignore blank
    sRegex = chomp(sRegex, sNonWordStarMarker);
    if (sRegex === "") return null;

    // Now escape special regex chars in the string
    sRegex = sRegex.replace(/[[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
    sRegex = sRegex.replace("-", "");
    // add optional hyphens and skip spaces, so that we match that are wrapped to the next line
    sRegex = sRegex.split("").join("-?\\s*");
    // let newRegex = "";
    // for (let i = 0; i < sRegex.length; i++) {
    //   newRegex = newRegex + sRegex[i] + "?-";
    // }
    // sRegex = newRegex;

    // Put a \W* instead of the \0 we added earlier
    sRegex = sRegex.replaceAll(sNonWordStarMarker, `\\W*`);

    // Surround with word boundaries if short
    // if (isShort) sRegex = `\\b${sRegex}\\b`;

    // Add word boundary even if the word is long
    sRegex = `\\b${sRegex}\\b`;

    // look for only exact match, even if it is long. ignoring the spaces
    // sRegex = new RegExp("\\b\\s*" + sRegex + "\\s*\\b", "gi");

    //console.log(sRegex);

    let re = safeRegEx(sRegex);
    if (!re) {
      // Ignore rogue expressions
      console.log("Bad regex", sPhrase, sRegex);
      return null;
    }

    return re;
  };

  getSpanForCharsAndFullText = () => {
    // Concatenate all the spans into a single string and also map each character
    // in the string to the span it came from
    let sText = "";
    this.arrIdxSpan = []; // span index for each character in sText
    this.arrSpanIdx = []; // range of characters for each span

    const elems = this.arrElemSpans;
    for (let iSpan = 0, iChar = 0; iSpan < elems.length; ++iSpan) {
      const sSpanText = " " + elems[iSpan].textContent + " ";

      if (sSpanText.length > 0) {
        sText += sSpanText;
        this.arrIdxSpan.push(...Array(sSpanText.length).fill(iSpan));
      }

      // Save the range of text index for this span
      this.arrSpanIdx[iSpan] = [iChar + 1, iChar + 1 + sSpanText.length];

      iChar += sSpanText.length;
    }
    this.sText = sText;

    console.log("Stext : ", sText);

    // console.log("Formed sText : ", {
    //   sText: this.sText,
    //   len: sText.length,
    //   last20: sText.substr(sText.length - 400),
    // });
  };

  // Get the phrase hints which have a citation like "[N]", and return a dict of N -> arrayidx
  getCitationIndices = () => {
    for (let i = 0; i < this.arrPhraseByLength.length; ++i) {
      const sPhrase = this.arrPhraseByLength[i];
      const arrMatch = sPhrase.match(/^\[(\d+)\]$/g);
      if (arrMatch && arrMatch.length > 0) {
        const nCite = sPhrase.substr(1, sPhrase.length - 2);
        this.dctCitations[nCite] = i;
        //console.log(sPhrase, nCite);
      }
    }
  };

  getMatchData = () => {
    // Get the necessary data to start matching
    this.getSpanForCharsAndFullText();
    this.getCitationIndices();

    // console.log(this.dctCitations);

    let sTextTemp = this.sText;

    console.log("First sTextTemp : ", sTextTemp);

    const highlightMatches = [];
    // const start = new Date();
    for (let i = 0; i < this.highlightsList.length; i++) {
      console.log("refmatches : start", i);
      let annotation = this.highlightsList[i]?.annotation;
      let re = this.highlightsList[i]?.regex;
      let id = this.highlightsList[i]?.id;

      const newRegex = new RegExp(re, "gi");
      console.log("NEW REGEX : ", newRegex);
      const highlightRegexMatches = [...sTextTemp.matchAll(newRegex)];

      let arrEntries = highlightRegexMatches
        .filter((m) => m[0].length > 1) //word's length > 1
        .map((match) => {
          // console.log(
          //   "3HERE",
          //   sTextTemp.slice(match.index, match.index + match[0].length, i)
          // );
          return [match.index, match.index + match[0].length, annotation, id];
        });
      if (arrEntries.length > 0) {
        highlightMatches.push(arrEntries[0]);
      }

      // To prevent overlapping matches, remove the phrase from the text by blanking it out with '\0'
      // This is a bit of a hack, but it works
      for (let j = 0; j < highlightRegexMatches.length; ++j) {
        const match = highlightRegexMatches[j];
        const sMatch = match[0];
        const iStart = match.index;
        const iEnd = iStart + sMatch.length;
        sTextTemp =
          sTextTemp.substr(0, iStart) +
          "\0".repeat(sMatch.length) +
          sTextTemp.substr(iEnd);
      }

      this.highlightsMatches = highlightMatches;
    }

    const arrAllMatches = []; // array of matches for all phrases containing [start, end, phrase index, isCitation]

    for (let i = 0; i < this.arrRegex.length; ++i) {
      let re = this.arrRegex[i];
      // console.log("sTextTemp : ", sTextTemp);
      // Find all matches for this phrase
      // console.log("Current regex : ", re);
      const newRegex = new RegExp(re, "gi");
      const arrRXMatches = [...sTextTemp.matchAll(newRegex)]; //[]

      // console.log("target text : ", { sTextTemp, len: sTextTemp.length });

      // console.log("Regex matches : ", { arrRXMatches });

      let arrEntries = arrRXMatches
        .filter((m) => m[0].length > 1) //word's length > 1
        .map((match) => [match.index, match.index + match[0].length, i]);
      // console.log("arrEntries : ", arrEntries);
      if (arrEntries.length > 0) {
        arrAllMatches.push(...arrEntries);
        // console.log("Pushing into allArrMatches : ", arrAllMatches);
      }

      // To prevent overlapping matches, remove the phrase from the text by blanking it out with '\0'
      // This is a bit of a hack, but it works
      for (let i = 0; i < arrRXMatches.length; ++i) {
        const match = arrRXMatches[i];
        const sMatch = match[0];
        const iStart = match.index;
        const iEnd = iStart + sMatch.length;
        sTextTemp =
          sTextTemp.substr(0, iStart) +
          "\0".repeat(sMatch.length) +
          sTextTemp.substr(iEnd);
      }
    }

    // reset sTextTemp
    // sTextTemp = this.sText;

    // console.log(
    //   "Search sTextTemp : ",
    //   sTextTemp.slice(sTextTemp.search("References"))
    // );
    // find publication matches
    const arrpubMatches = [];
    for (let i = 0; i < this.publicationsList.length; i++) {
      let baseurlPath = this.publicationsList[i]?.url_path;
      let re = this.publicationsList[i]?.regex;
      // console.log("pubRegex : ", {
      //   sTextTemp,
      //   re,
      //   len: sTextTemp.length,
      //   found: sTextTemp.search("References"),
      //   text: sTextTemp.slice(sTextTemp.search("References")),
      //   match: [...sTextTemp.matchAll(re)],
      // });
      const newRegex = new RegExp(re, "gi");
      const publicationRegexMatches = [...sTextTemp.matchAll(newRegex)];

      // console.log({ publicationRegexMatches });

      let arrEntries = publicationRegexMatches
        .filter((m) => m[0].length > 1) //word's length > 1
        .map((match) => {
          // console.log(
          //   "3HERE",
          //   sTextTemp.slice(match.index, match.index + match[0].length, i)
          // );
          return [
            match.index,
            match.index + match[0].length,
            `${baseurlPath}${match[0]}`,
          ];
        });
      console.log("publicationsList arrEntries : ", arrEntries);
      // console.log("arrEntries : ", arrEntries);
      if (arrEntries.length > 0) {
        arrpubMatches.push(...arrEntries);
        // arrpubMatches.push([arrEntries[0], ...arrEntries?.slice(1)]);
      }

      // To prevent overlapping matches, remove the phrase from the text by blanking it out with '\0'
      // This is a bit of a hack, but it works
      for (let i = 0; i < publicationRegexMatches.length; ++i) {
        const match = publicationRegexMatches[i];
        const sMatch = match[0];
        const iStart = match.index;
        const iEnd = iStart + sMatch.length;
        sTextTemp =
          sTextTemp.substr(0, iStart) +
          "\0".repeat(sMatch.length) +
          sTextTemp.substr(iEnd);
      }

      this.publicationMatches = arrpubMatches;
    }

    const arrRefMatches = [];
    for (let i = 0; i < this.referencesList.length; i++) {
      let url = this.referencesList[i]?.url;
      let re = this.referencesList[i]?.regex;
      let id = this.referencesList[i]?.id;

      const newRegex = new RegExp(re, "gi");
      const referenceRegexMatches = [...sTextTemp.matchAll(newRegex)];

      let arrEntries = referenceRegexMatches
        .filter((m) => m[0].length > 1) //word's length > 1
        .map((match) => {
          // console.log(
          //   "3HERE",
          //   sTextTemp.slice(match.index, match.index + match[0].length, i)
          // );
          return [match.index, match.index + match[0].length, url, id];
        });
      console.log("referencesList arrEntries : ", arrEntries);
      console.log("arrEntries : ", arrEntries);
      if (arrEntries.length > 0) {
        arrRefMatches.push(...arrEntries);
      }

      // To prevent overlapping matches, remove the phrase from the text by blanking it out with '\0'
      // This is a bit of a hack, but it works
      for (let j = 0; j < referenceRegexMatches.length; ++j) {
        const match = referenceRegexMatches[j];
        const sMatch = match[0];
        const iStart = match.index;
        const iEnd = iStart + sMatch.length;
        sTextTemp =
          sTextTemp.substr(0, iStart) +
          "\0".repeat(sMatch.length) +
          sTextTemp.substr(iEnd);
      }

      this.referencesMatches = arrRefMatches;
    }

    // Handle citation matching - match one or more numbers within square brackets and map them to the phrase [n]
    // First match contents in square brackets, then extract the numbers in those
    // const rxCite = /[[^[\]]+\]/g;
    // const rxNum = /\d+/g;

    // Grab [...]
    // const arrRXMatches = [...sTextTemp.matchAll(rxCite)];
    // console.log("arrRXMatches : ", arrRXMatches);
    // for (let i = 0; i < arrRXMatches.length; ++i) {
    //   // console.log("Inside arrRXMatches : ", arrRXMatches);
    //   const match = arrRXMatches[i];
    //   const idx = match.index;
    //   const arrMissing = [];

    //   // Find the numbers inside this [...]
    //   const arrNumMatches = [...match[0].matchAll(rxNum)];
    //   const nNums = arrNumMatches.length;
    //   for (let i = 0; i < nNums; ++i) {
    //     const iCite = arrNumMatches[i][0];

    //     // If there is only 1 citation we split/highlight including the []
    //     const nStart = nNums === 1 ? idx : idx + arrNumMatches[i].index;
    //     const nEnd = nStart + (nNums === 1 ? match[0].length : iCite.length);

    //     // Check if this citation is in our dict and if so add it
    //     if (iCite in this.dctCitations) {
    //       //console.log([nStart, nEnd, this.dctCitations[iCite], true])
    //       arrAllMatches.push([nStart, nEnd, this.dctCitations[iCite], true]);
    //     } else {
    //       arrMissing.push(iCite);
    //     }
    //   }

    //   if (arrMissing.length) {
    //     console.log(
    //       `Missing citations ${arrMissing.join(", ")} in ${match[0]}`
    //     );
    //   }
    // }

    // console.log("1. arrAllMatches : ", {
    //   arrAllMatches,
    //   len: this.dctPhrases,
    // });

    // Highlight all instances
    // Sort by start position
    console.log("highlightsMatches : ", {
      highlightsMatches: this.highlightsMatches,
    });
    arrAllMatches.sort((a, b) => a[0] - b[0]);
    this.arrMatches = arrAllMatches;

    // console.log("arrAllMatches : ", { arrAllMatches });

    // Highlight only the first occurance of the current page
    // const firstMatches = [];
    // console.log(`All matches for in the curr target text : `, arrAllMatches);

    // for (let i = 0; i < this.arrRegex.length; i++) {
    //   const filteredMatches = arrAllMatches?.filter((ele) => ele[2] === i);

    //   console.log(`All matches for : ${this.arrRegex[i]} : `, filteredMatches);

    //   if (filteredMatches.length > 0) {
    //     const result = filteredMatches.reduce((prev, curr) => {
    //       return prev[0] < curr[0] ? prev : curr;
    //     });
    //     firstMatches.push(result);
    //     console.log("firstMatch : ", result);
    //   }
    // }
    // console.log({ firstMatches });
    // // the following breaks , have to sort to make it work, but why
    // this.arrMatches = firstMatches;

    // this.arrMatches = firstMatches.sort((a, b) => a[0] - b[0]);
  };

  // Split out the highlightable spans to the matches
  splitSpans = () => {
    // First go through all the matches and build a map of span-index to array of [match, highlight subscript range]
    let dctSpanMatches = {};
    let publicationMatchesBySpan = {};
    let referencesMatchesBySpan = {};
    let highlightsMatchesBySpan = {};
    for (let i = 0; i < this.arrMatches.length; ++i) {
      // console.log({ allMatchesOne: this.arrMatches });
      // console.log("arrIdxSpan : ", this.arrIdxSpan);
      const match = this.arrMatches[i];
      // console.log("currMatch : ", match);
      const iBeg = match[0];
      const iEnd = match[1];

      // Get the span index for the start and end of the match
      const iSpanBeg = this.arrIdxSpan[iBeg];
      const iSpanEnd = this.arrIdxSpan[iEnd];

      // console.log({ iSpanBeg, iSpanEnd });
      // check for matches only if we are above the reference section, "-1", as the the fpage starts from 0

      if (this.fPage < this.referencePage - 1) {
        for (let iSpan = iSpanBeg; iSpan <= iSpanEnd; ++iSpan) {
          // The starting absolute highlight index for this span is the maximum of this spans start index and the match start index
          // console.log(`NEW SPAN : ${i}`);
          const iSpanIdxBeg = this.arrSpanIdx[iSpan][0];
          const iSpanIdxEnd = this.arrSpanIdx[iSpan][1];
          // console.log({ iSpanIdxBeg, iSpanIdxEnd, iBeg, iEnd });
          const iSpanMatchBeg = Math.max(iSpanIdxBeg, iBeg);
          const iSpanMatchEnd = Math.min(iSpanIdxEnd, iEnd);

          // Convert the absolute highlight index to a relative index for this span
          const iSpanMatchBegRel = iSpanMatchBeg - iSpanIdxBeg;
          const iSpanMatchEndRel = iSpanMatchEnd - iSpanIdxBeg;

          // Add this match to the dictionary of span matches (if the entry doesn't exist, create it)
          if (!dctSpanMatches[iSpan]) dctSpanMatches[iSpan] = [];
          dctSpanMatches[iSpan].push([
            i,
            iSpanMatchBegRel,
            iSpanMatchEndRel,
            match[2],
          ]); // [matchIdx, spanStart, spanEnd, phraseIdx]
        }
      }
      // console.log(
      //   "----------------------------------For loop over---------------------------------------------"
      // );
    }

    for (let i = 0; i < this.publicationMatches.length; ++i) {
      const match = this.publicationMatches[i];
      // console.log("currMatch : ", match);
      const iBeg = match[0];
      const iEnd = match[1];

      // Get the span index for the start and end of the match
      const iSpanBeg = this.arrIdxSpan[iBeg];
      const iSpanEnd = this.arrIdxSpan[iEnd];

      // console.log({ iSpanBeg, iSpanEnd });

      // check for matches only if we are above the reference section, "-1", as the the fpage starts from 0

      // if (this.fPage + 2 > this.referencePage) {
      for (let iSpan = iSpanBeg; iSpan <= iSpanEnd; ++iSpan) {
        // The starting absolute highlight index for this span is the maximum of this spans start index and the match start index
        // console.log(`NEW SPAN : ${i}`);
        const iSpanIdxBeg = this.arrSpanIdx[iSpan][0];
        const iSpanIdxEnd = this.arrSpanIdx[iSpan][1];
        // console.log({ iSpanIdxBeg, iSpanIdxEnd, iBeg, iEnd });
        const iSpanMatchBeg = Math.max(iSpanIdxBeg, iBeg);
        const iSpanMatchEnd = Math.min(iSpanIdxEnd, iEnd);

        // Convert the absolute highlight index to a relative index for this span
        const iSpanMatchBegRel = iSpanMatchBeg - iSpanIdxBeg;
        const iSpanMatchEndRel = iSpanMatchEnd - iSpanIdxBeg;

        // Add this match to the dictionary of span matches (if the entry doesn't exist, create it)
        if (!publicationMatchesBySpan[iSpan])
          publicationMatchesBySpan[iSpan] = [];
        publicationMatchesBySpan[iSpan].push([
          i,
          iSpanMatchBegRel,
          iSpanMatchEndRel,
          match[2],
        ]); // [matchIdx, spanStart, spanEnd, phraseIdx]
      }
      // }
      // console.log(
      //   "----------------------------------For loop over---------------------------------------------"
      // );
    }

    for (let i = 0; i < this.referencesMatches.length; ++i) {
      const match = this.referencesMatches[i];
      const iBeg = match[0];
      const iEnd = match[1];

      // Get the span index for the start and end of the match
      const iSpanBeg = this.arrIdxSpan[iBeg];
      const iSpanEnd = this.arrIdxSpan[iEnd];

      // console.log({ iSpanBeg, iSpanEnd });

      // check for matches only if we are above the reference section, "-1", as the the fpage starts from 0
      if (this.fPage + 2 >= this.referencePage) {
        for (let iSpan = iSpanBeg; iSpan <= iSpanEnd; ++iSpan) {
          // The starting absolute highlight index for this span is the maximum of this spans start index and the match start index
          // console.log(`NEW SPAN : ${i}`);
          const iSpanIdxBeg = this.arrSpanIdx[iSpan][0];
          const iSpanIdxEnd = this.arrSpanIdx[iSpan][1];
          // console.log({ iSpanIdxBeg, iSpanIdxEnd, iBeg, iEnd });
          const iSpanMatchBeg = Math.max(iSpanIdxBeg, iBeg);
          const iSpanMatchEnd = Math.min(iSpanIdxEnd, iEnd);

          // Convert the absolute highlight index to a relative index for this span
          const iSpanMatchBegRel = iSpanMatchBeg - iSpanIdxBeg;
          const iSpanMatchEndRel = iSpanMatchEnd - iSpanIdxBeg;

          // Add this match to the dictionary of span matches (if the entry doesn't exist, create it)
          if (!referencesMatchesBySpan[iSpan])
            referencesMatchesBySpan[iSpan] = [];
          referencesMatchesBySpan[iSpan].push([
            i,
            iSpanMatchBegRel,
            iSpanMatchEndRel,
            match[2],
            match[3],
          ]); // [matchIdx, spanStart, spanEnd, url, id]
        }
      }
      // console.log(
      //   "----------------------------------For loop over---------------------------------------------"
      // );
    }

    for (let i = 0; i < this.highlightsMatches.length; ++i) {
      const match = this.highlightsMatches[i];
      // console.log("currMatch : ", match);
      const iBeg = match[0];
      const iEnd = match[1];

      // Get the span index for the start and end of the match
      const iSpanBeg = this.arrIdxSpan[iBeg];
      const iSpanEnd = this.arrIdxSpan[iEnd];

      // console.log({ iSpanBeg, iSpanEnd });

      // check for matches only if we are above the reference section, "-1", as the the fpage starts from 0

      // if (this.fPage + 2 > this.referencePage) {
      for (let iSpan = iSpanBeg; iSpan <= iSpanEnd; ++iSpan) {
        // The starting absolute highlight index for this span is the maximum of this spans start index and the match start index
        // console.log(`NEW SPAN : ${i}`);
        const iSpanIdxBeg = this.arrSpanIdx[iSpan][0];
        const iSpanIdxEnd = this.arrSpanIdx[iSpan][1];
        // console.log({ iSpanIdxBeg, iSpanIdxEnd, iBeg, iEnd });
        const iSpanMatchBeg = Math.max(iSpanIdxBeg, iBeg);
        const iSpanMatchEnd = Math.min(iSpanIdxEnd, iEnd);

        // Convert the absolute highlight index to a relative index for this span
        const iSpanMatchBegRel = iSpanMatchBeg - iSpanIdxBeg;
        const iSpanMatchEndRel = iSpanMatchEnd - iSpanIdxBeg;

        // Add this match to the dictionary of span matches (if the entry doesn't exist, create it)
        if (!highlightsMatchesBySpan[iSpan])
          highlightsMatchesBySpan[iSpan] = [];
        highlightsMatchesBySpan[iSpan].push([
          i,
          iSpanMatchBegRel,
          iSpanMatchEndRel,
          match[2],
        ]); // [matchIdx, spanStart, spanEnd, phraseIdx]
      }
      // }
      // console.log(
      //   "----------------------------------For loop over---------------------------------------------"
      // );
    }

    // for (let i = 0; i < this.referencesMatches.length; ++i) {
    //   // console.log({ allMatchesOne: this.arrMatches });
    //   // console.log("arrIdxSpan : ", this.arrIdxSpan);
    //   const match = this.referencesMatches[i];
    //   // console.log("currMatch : ", match);
    //   const iBeg = match[0];
    //   const iEnd = match[1];

    //   // Get the span index for the start and end of the match
    //   const iSpanBeg = this.arrIdxSpan[iBeg];
    //   const iSpanEnd = this.arrIdxSpan[iEnd];

    //   // console.log({ iSpanBeg, iSpanEnd });

    //   // check for matches only if we are above the reference section, "-1", as the the fpage starts from 0

    //   if (this.fPage < this.referencePage - 1) {
    //     for (let iSpan = iSpanBeg; iSpan <= iSpanEnd; ++iSpan) {
    //       // The starting absolute highlight index for this span is the maximum of this spans start index and the match start index
    //       // console.log(`NEW SPAN : ${i}`);
    //       const iSpanIdxBeg = this.arrSpanIdx[iSpan][0];
    //       const iSpanIdxEnd = this.arrSpanIdx[iSpan][1];
    //       // console.log({ iSpanIdxBeg, iSpanIdxEnd, iBeg, iEnd });
    //       const iSpanMatchBeg = Math.max(iSpanIdxBeg, iBeg);
    //       const iSpanMatchEnd = Math.min(iSpanIdxEnd, iEnd);

    //       // Convert the absolute highlight index to a relative index for this span
    //       const iSpanMatchBegRel = iSpanMatchBeg - iSpanIdxBeg;
    //       const iSpanMatchEndRel = iSpanMatchEnd - iSpanIdxBeg;

    //       // Add this match to the dictionary of span matches (if the entry doesn't exist, create it)
    //       if (!referencesMatchesBySpan[iSpan])
    //         referencesMatchesBySpan[iSpan] = [];
    //       referencesMatchesBySpan[iSpan].push([
    //         i,
    //         iSpanMatchBegRel,
    //         iSpanMatchEndRel,
    //         match[2],
    //       ]); // [matchIdx, spanStart, spanEnd, phraseIdx]
    //     }
    //   }
    //   // console.log(
    //   //   "----------------------------------For loop over---------------------------------------------"
    //   // );
    // }
    // console.log("All spans : ", { dctSpanMatches });
    // console.log("dctPhrases : ", this.arrPhraseByLength);
    // Now iterate through this dictionary and split the spans
    let dctSpanFirstMatches = {};
    for (let i = 0; i < this.arrPhraseByLength.length; i++) {
      const filteredMatches = [];
      for (const ele in dctSpanMatches) {
        const currSpanMatches = dctSpanMatches[ele];
        for (let matchIdx = 0; matchIdx < currSpanMatches.length; matchIdx++) {
          // console.log(currSpanMatches[matchIdx]);
          const currMatch = currSpanMatches[matchIdx];
          const currSpan = this.arrElemSpans[ele];
          const currMatchRect = currSpan.getBoundingClientRect();
          const { top: currTop } = currMatchRect; //to check the curr Match is still in the view
          if (
            currMatch[3] === i &&
            currSpan.innerText.trim().length &&
            currTop > 0
          ) {
            //matches with current ele and is not empty
            filteredMatches.push([...currMatch, ele]); // [matchIdx, spanStart, spanEnd, phraseIdx, spanIdx]
          }
        }
      }
      // console.log(
      //   "Index, phrase , filteredMatches : ",
      //   i,
      //   // this.dctIdxPhrase[i],
      //   this.arrPhraseByLength[i],
      //   filteredMatches
      // );

      if (filteredMatches.length > 0) {
        const sortedFilteredMatches = filteredMatches.sort((a, b) => {
          const spanIndexA = a[4];
          const spanA = this.arrElemSpans[spanIndexA];
          // const { top: topA, left: leftA } = spanA.style;

          const spanIndexB = b[4];
          const spanB = this.arrElemSpans[spanIndexB];
          // const { top: topB, left: leftB } = spanB.style;
          console.log({
            spanAHTML: spanA.innerHTML,
            spanBHTML: spanB.innerHTML,
          });
          // console.log({ spanA, spanB });

          const aRect = spanA.getBoundingClientRect();
          const { top: topA, left: leftA } = aRect;
          const bRect = spanB.getBoundingClientRect();
          const { top: topB, left: leftB } = bRect;
          // console.log({ topA, topB, leftA, leftB, a4: a[4], b4: b[4] });
          // console.log({
          //   aRect: spanA.getBoundingClientRect(),
          //   bRect: spanB.getBoundingClientRect(),
          // });
          if (topA === topB) {
            return leftA - leftB;
          }
          return topA - topB;
        });
        // console.log("sortedFilteredMatches : ", sortedFilteredMatches);
        let iPage = this.fPage | 0;
        let fFrac = this.fPage - iPage;
        let firstMatch = [];
        const currPageEnd = (1 - fFrac) * this.pxPageH;
        const topMatchSpanIdx = sortedFilteredMatches[0][4];
        const topMatchSpan = this.arrElemSpans[topMatchSpanIdx];
        const topMatchRect = topMatchSpan.getBoundingClientRect();
        const { top: firstMatchTop } = topMatchRect;
        // console.log("FFrac, pxPageH : ", fFrac, this.pxPageH);
        // console.log("First Match : ", {
        //   sortedFilteredMatches: sortedFilteredMatches[0],
        // });
        // console.log({
        //   currPageEnd,
        //   firstMatchTop,
        //   condition: firstMatchTop > currPageEnd,
        // });
        // console.log({
        //   fpage: this.fPage + 2,
        //   referencePage: this.referencePage,
        //   condidtion: this.fPage + 2 > this.referencePage,
        // });
        if (
          this.fPage + 2 > this.referencePage &&
          firstMatchTop > currPageEnd
        ) {
          // console.log({ currPageEnd });
          //skip if the curr Match is in or after the refernce page
          continue;
        } else {
          firstMatch.push(sortedFilteredMatches[0]);

          // corner case : O besity (Where O is cap h3 and besity is paragraph in the same span)
          for (let i = 1; i < sortedFilteredMatches.length; i++) {
            const [matchIdxCurr, spanStartCurr] = sortedFilteredMatches[i];
            const [matchIdxPrev, spanStartPrev] =
              firstMatch[firstMatch.length - 1];

            console.log({
              matchIdxCurr,
              spanStartCurr,
              matchIdxPrev,
              spanStartPrev,
            });
            // If matchIdx and spanStart are same, push the current element to firstMatch
            if (matchIdxCurr === matchIdxPrev) {
              firstMatch.push(sortedFilteredMatches[i]);
            } else {
              // If not same, break the loop
              break;
            }
          }
        }
        // console.log("Puhing in : ", firstMatch, this.arrPhraseByLength[i]);

        // dctSpanFirstMatches[firstMatch[4]] = firstMatch.slice(0, 4);
        // if (!dctSpanFirstMatches[firstMatch[4]]) {
        //   dctSpanFirstMatches[firstMatch[4]] = [];
        // }
        // dctSpanFirstMatches[firstMatch[4]].push(firstMatch.slice(0, 4));

        // Iterate through each element of firstMatch
        for (const element of firstMatch) {
          // Get the spanIdx of the element
          const spanIdx = element[4];

          // If spanIdx doesn't exist in dctSpanFirstMatches, initialize it as an empty array
          if (!dctSpanFirstMatches[spanIdx]) {
            dctSpanFirstMatches[spanIdx] = [];
          }

          // Push the first four elements of the current element into dctSpanFirstMatches
          dctSpanFirstMatches[spanIdx].push(element.slice(0, 4));
        }
      }

      //fpage starts from 0

      // if (this.fPage >= this.referencePage - 1) {
      //   // remove all matches if we are under references section
      //   dctSpanFirstMatches = {};
      //   dctSpanMatches = {};
      // }

      // console.log("dctSpanFirstMatches : ", dctSpanFirstMatches);
    }
    // for (const iSpan in dctSpanMatches) {
    for (const iSpan in dctSpanFirstMatches) {
      // Reverse first so that we dont mess the indexes when we split the spans
      // console.log({
      //   iSpan,
      //   curr: dctSpanFirstMatches[iSpan],
      //   supposed: dctSpanMatches[iSpan],
      // });
      // console.log("Before reversal : ", dctSpanFirstMatches[iSpan]);
      // weird fix, sort instead of reverse (original)
      const arrMatches = dctSpanFirstMatches[iSpan]
        .slice()
        .sort((a, b) => b[0] - a[0]);

      // console.log("After reversal : ", dctSpanFirstMatches[iSpan], arrMatches);
      let currentSpan = this.arrElemSpans[iSpan];
      let sText = this.arrElemSpans[iSpan].textContent;
      for (const match of arrMatches) {
        // If the match is not entire span, then split the span
        // ALso check if its a citation match, in which case we split the span regardless
        if (
          match[1] !== 0 ||
          match[2] !== sText.length ||
          this.arrMatches[match[0]][3]
        ) {
          // Wrap this range substring with a <t-x> element, adding an attribute with the match index
          const sTextBeg = sText.substring(0, match[1]);
          const sTextMid = sText.substring(match[1], match[2]);
          const sTextEnd = sText.substring(match[2]);
          console.log("If block : ", { sTextBeg, sTextMid, sTextEnd, match });
          sText =
            sTextBeg +
            `<t-x data-match=${match[0]}>${sTextMid}</t-x>` +
            sTextEnd;
          this.arrElemSpans[iSpan].innerHTML = sText;
        } else {
          // If the match is the entire span, then just add the attribute for the match index
          console.log("Else block : ", this.arrElemSpans[iSpan].innerHTML);
          // this.arrElemSpans[iSpan].setAttribute("data-match", match[0]);

          this.arrElemSpans[
            iSpan
          ].innerHTML = `<t-x data-match=${match[0]}>${this.arrElemSpans[iSpan].innerHTML}</t-x>`;
        }
      }
    }

    // for publications
    console.log("publicationMatchesBySpan : ", publicationMatchesBySpan);
    // publicationMatchesBySpan = {};
    for (const iSpan in publicationMatchesBySpan) {
      let sText = this.arrElemSpans[iSpan].textContent;
      console.log("curr iSPan : ", iSpan);

      let pubMatches = publicationMatchesBySpan[iSpan]
        ?.slice()
        ?.sort((a, b) => b[0] - a[0]);
      if (!pubMatches) {
        console.log("No PUBSTR");
        pubMatches = [];
      }

      for (const match of pubMatches) {
        console.log("currPubMatch : ", match);
        // const newMatch =
        // If the match is not entire span, then split the span
        if (match[1] !== 0 || match[2] !== sText.length) {
          console.log("PUBSTR IF");
          console.log("Curr sText : ", sText);
          const sTextBeg = sText.substring(0, match[1]);
          const sTextMid = sText.substring(match[1], match[2]);
          const sTextEnd = sText.substring(match[2]);
          sText =
            sTextBeg +
            `<a class="publication-link" href="${match[3]}" target="_blank">${sTextMid}</a>` +
            sTextEnd;
          console.log("After sText : ", sText);
          this.arrElemSpans[iSpan].innerHTML = sText;
        } else {
          console.log("PUBSTR ELSE");
          this.arrElemSpans[
            iSpan
          ].innerHTML = `<a class="publication-link" href="${match[3]}" target="_blank">${this.arrElemSpans[iSpan].innerHTML}</a>`;
        }
      }
    }

    console.log("referencesMatchesBySpan : ", referencesMatchesBySpan);

    for (const iSpan in referencesMatchesBySpan) {
      let sText = this.arrElemSpans[iSpan].textContent;
      console.log("MEATCH curr iSPan : ", iSpan);

      let refMatches = referencesMatchesBySpan[iSpan]
        ?.slice()
        ?.sort((a, b) => b[0] - a[0]);
      if (!refMatches) {
        console.log("No RefStr");
        refMatches = [];
      }

      for (const match of refMatches) {
        console.log("currRefMatches : ", match);
        const linkId = match[3];
        // const newMatch =
        // If the match is not entire span, then split the span
        if (match[1] !== 0 || match[2] !== sText.length) {
          console.log("PUBSTR IF");
          console.log("Curr sText : ", sText);
          const sTextBeg = sText.substring(0, match[1]);
          const sTextMid = sText.substring(match[1], match[2]);
          const sTextEnd = sText.substring(match[2]);
          sText =
            sTextBeg +
            `<a class="reference-link" href="${match[3]}"  link-id=${linkId} target="_blank">${sTextMid}</a>` +
            sTextEnd;
          console.log("After sText : ", sText);
          this.arrElemSpans[iSpan].innerHTML = sText;
        } else {
          console.log("PUBSTR ELSE");
          this.arrElemSpans[
            iSpan
          ].innerHTML = `<a class="reference-link" href="${match[3]}"  link-id=${linkId} target="_blank">${this.arrElemSpans[iSpan].innerHTML}</a>`;
        }
      }
    }

    for (const iSpan in highlightsMatchesBySpan) {
      // Reverse first so that we dont mess the indexes when we split the spans
      // console.log({
      //   iSpan,
      //   curr: dctSpanFirstMatches[iSpan],
      //   supposed: dctSpanMatches[iSpan],
      // });
      // console.log("Before reversal : ", dctSpanFirstMatches[iSpan]);
      // weird fix, sort instead of reverse (original)
      const arrMatches = highlightsMatchesBySpan[iSpan]
        .slice()
        .sort((a, b) => b[0] - a[0]);

      // console.log("After reversal : ", dctSpanFirstMatches[iSpan], arrMatches);
      let currentSpan = this.arrElemSpans[iSpan];
      let sText = this.arrElemSpans[iSpan].textContent;
      for (const match of arrMatches) {
        // If the match is not entire span, then split the span
        // ALso check if its a citation match, in which case we split the span regardless
        if (
          match[1] !== 0 ||
          match[2] !== sText.length ||
          (this.arrMatches.length > 3 && this.arrMatches[match[0]][3])
        ) {
          // Wrap this range substring with a <t-x> element, adding an attribute with the match index
          const sTextBeg = sText.substring(0, match[1]);
          const sTextMid = sText.substring(match[1], match[2]);
          const sTextEnd = sText.substring(match[2]);
          console.log("If block : ", { sTextBeg, sTextMid, sTextEnd, match });
          sText =
            sTextBeg +
            `<h-x highlight-id=${match[0]}>${sTextMid}</h-x>` +
            sTextEnd;
          this.arrElemSpans[iSpan].innerHTML = sText;
        } else {
          // If the match is the entire span, then just add the attribute for the match index
          console.log("Else block : ", this.arrElemSpans[iSpan].innerHTML);
          // this.arrElemSpans[iSpan].setAttribute("data-match", match[0]);

          this.arrElemSpans[
            iSpan
          ].innerHTML = `<h-x highlight-id=${match[0]}>${this.arrElemSpans[iSpan].innerHTML}</h-x>`;
        }
      }
    }

    // Get the elements that have the match attribute and add hover handling
    $(this.elemText).on("mouseenter", "[data-match]", this.onTextHoverIn);
    $(this.elemText).on("mouseleave", "[data-match]", this.onTextHoverOut);
    $(this.elemTooltip).mouseleave(this.onTooltipHoverOut);
    //$(this.elemTooltip).mouseenter(this.onTextHoverIn);

    $(this.elemText).on("mouseenter", "[link-id]", this.onReferenceHoverIn);
    $(this.elemText).on("mouseleave", "[link-id]", this.onReferenceHoverOut);

    $(this.elemText).on(
      "mouseenter",
      "[highlight-id]",
      this.onHighlightHoverIn
    );
    $(this.elemText).on(
      "mouseleave",
      "[highlight-id]",
      this.onHighlightHoverOut
    );

    // const elems = document.getElementsByTagName("ref-x");
    // console.log("\n\n\n\n\n\n Elemes : ", { elems });
    // [...elems]?.forEach((ele) => {
    //   ele.addEventListener("mouseenter", this.onReferenceHoverIn);
    // });

    // for (const ele in elems) {
    //   ele.addEventListener("mouseenter", this.onReferenceHoverIn);
    // }
  };

  // (de)Highlight all the elements with the given match id (-1 means all)
  doHighLight = (idxHighlight, bHighlight) => {
    let elems =
      idxHighlight === -1
        ? $("[data-match]")
        : $(`[data-match="${idxHighlight}"]`);
    for (const k of elems) {
      k.style.backgroundColor = bHighlight ? "#00FF0050" : "transparent";
    }
  };

  showToolTip = (elem, idxHighlight) => {
    if (this.idxHighlight !== idxHighlight) return;

    idxHighlight = this.idxHighlight;

    // Get the phrase for this match
    const phrase = this.arrPhraseByLength[this.arrMatches[idxHighlight][2]];

    // Add tooltip
    this.elemTooltipTextContent.innerHTML = `<b>${phrase}:</b><br>${this.dctPhrases[phrase]}`;

    // Set a data-id attribute on the tooltip to track which match it is for
    this.elemTooltip.setAttribute("data-id", idxHighlight);

    // We want the tooltip to completely obscure the element, so that we dont get a mouse-out event
    const rcElem = elem.getBoundingClientRect();

    // Calculate the middle of the element and get the tooltip x so that its centered on the element
    const xMid = rcElem.left + rcElem.width / 2;

    const wTooltip = this.elemContent.offsetWidth / 2;
    let xTooltip = xMid - wTooltip / 2;

    // If the tooltip x is outside the page clamp it to the edge
    if (xTooltip < 0) xTooltip = 0;
    if (xTooltip + wTooltip > this.elemContent.offsetWidth)
      xTooltip = this.elemContent.offsetWidth - wTooltip;

    this.elemTooltip.style.left = xTooltip + "px";
    this.elemTooltip.style.width = wTooltip + "px";

    this.elemTooltip.style.top =
      rcElem.top - this.elemTooltip.offsetHeight + "px";
    this.elemTooltip.style.width =
      ((this.elemContent.offsetWidth / 2) | 0) + "px";

    this.doShowElement(this.elemTooltip, true);

    // Save the rectangle of the element that the tooltip is for
    this.rcSelectBounds = this.xfContentRCToPdf(rcElem);
  };

  showAnnotatioNote = (elem, idxAnnotationHighlight) => {
    console.log("entered showANnotationNote : ", {
      elem,
      local: idxAnnotationHighlight,
      // globalHi: this.idxAnnotationHighlight,
    });
    // if (this.idxAnnotationHighlight !== idxAnnotationHighlight) return;

    // idxAnnotationHighlight = this.idxAnnotationHighlight;

    // Get the phrase for this match
    const phrase = this.highlightsMatches[idxAnnotationHighlight][2];
    console.log({ phrase, highlightsMatches: this.highlightsMatches });
    // Add tooltip
    this.elemAnnotationText.innerHTML = `<p>${phrase}<p>`;

    // Set a data-id attribute on the tooltip to track which match it is for
    this.elemAnnotationContainer.setAttribute(
      "data-id",
      idxAnnotationHighlight
    );

    // We want the tooltip to completely obscure the element, so that we dont get a mouse-out event
    const rcElem = elem.getBoundingClientRect();

    // Calculate the middle of the element and get the tooltip x so that its centered on the element
    const xMid = rcElem.left + rcElem.width / 2;

    const wTooltip = this.elemContent.offsetWidth / 2;
    // let xTooltip = xMid - wTooltip / 2;
    let xTooltip = xMid - wTooltip /4

    // If the tooltip x is outside the page clamp it to the edge
    if (xTooltip < 0) xTooltip = 0;
    if (xTooltip + wTooltip > this.elemContent.offsetWidth)
      xTooltip = this.elemContent.offsetWidth - wTooltip;

    this.elemAnnotationContainer.style.left = xTooltip + "px";
    // this.elemAnnotationContainer.style.width = wTooltip + "px";

    this.elemAnnotationContainer.style.top =
      rcElem.top - this.elemAnnotationContainer.offsetHeight + "px";
    this.elemAnnotationContainer.style.maxWidth =
      ((this.elemContent.offsetWidth / 2) | 0) + "px";

    this.doShowElement(this.elemAnnotationContainer, true);
    console.log({ elemAnnotationContainer: this.elemAnnotationContainer });
    // Save the rectangle of the element that the tooltip is for
    this.rcSelectBounds = this.xfContentRCToPdf(rcElem);
  };

  // Helper methods for transforming co-ords PDF and DOM/Canvas

  // Given pdf coordinates of a rect, return the canvas relative co-ords
  xfPdfToCanvas = (x, y, w, h) => {
    const fScale = this.pxCanvasW / this.dctPDFViewPort.width;
    // Now scale up the co-ords and return
    return [
      (x * fScale) | 0,
      (y * fScale) | 0,
      (w * fScale) | 0,
      (h * fScale) | 0,
    ];
  };

  // Given a DOM bounding box, return the pdf viewport co-ords as [x,y,w,h]
  // Adjust for scroll
  xfContentRCToPdf = (rc) => {
    const rcText = this.elemContainer.getBoundingClientRect();

    // First get rc DOM co-ordinates relative rcText
    let x = rc.left - rcText.left;
    let y = rc.top - rcText.top;
    let w = rc.width;
    let h = rc.height;

    // Adjust for scroll position
    const fPageFrac = this.fPage - (this.fPage | 0);
    y += this.pxPageH * fPageFrac;

    // Scale from DOM viewport to pdf viewport
    const fScale = this.dctPDFViewPort.width / this.pxViewportW;

    this.rcLTPage = this.fPage + (rc.top - rcText.top) / this.pxPageH;
    this.rcRBPage = this.fPage + (rc.bottom - rcText.top) / this.pxPageH;

    // Now scale up the co-ords and return
    return [
      (x * fScale) | 0,
      (y * fScale) | 0,
      (w * fScale) | 0,
      (h * fScale) | 0,
    ];
  };

  zoom = (val) => {
    if (!this.pdf) return this.nZoom;

    this.nZoom += val;
    if (this.nZoom > 200) this.nZoom = 200;
    if (this.nZoom < 30) this.nZoom = 30;
    this.elemContent.style.width = `${this.nZoom}%`;
    this.elemContent.style.left = `${
      this.nZoom < 100 ? (100 - this.nZoom) / 2 : 0
    }%`;
    this.renderViewPort();
    return this.nZoom;
  };

  onScroll = async () => {
    // Get the current scroll position and convert it to a page number, force it to 1/32 resolution at most
    const fNormalizedScrollPos =
      (this.elemContainer.scrollTop + 1) / this.pxScrollableH;
    const fPage = ((fNormalizedScrollPos * this.nPages * 32) | 0) / 32;
    if (fPage !== this.fPage) {
      this.fPage = fPage;
      this.render().then(this.doPageChange);
    }
  };

  onResize = async () => {
    await this.renderViewPort();
  };

  // Called to navigate to absolute page
  gotoPage(fPage) {
    console.log("GOTOPAGE");
    this.elemContainer.scrollTop = this.pxPageH * Math.floor(fPage);
  }

  // Called to navigate +/- from current page - returns integer page number (1 based)
  gotoPageRel(fOffset) {
    this.gotoPage(this.fPage + fOffset);
    console.log({ pageH: this.pxPageH, fPage: this.fPage });
  }

  toggleOverlay() {
    const existingOverlay = document.getElementById("overlay");
    if (existingOverlay) {
      // Remove the overlay if it already exists
      existingOverlay.remove();
      return;
    }

    // Add the overlay if it doesn't exist
    const pdfFrameElement = document.getElementById("pdf-frame");
    const overlayElement = document.createElement("div");
    overlayElement.id = "overlay";
    pdfFrameElement.appendChild(overlayElement);

    const canvasElement = document.createElement("canvas");
    canvasElement.id = "overlayCanvas";
    canvasElement.width = overlayElement.offsetWidth;
    canvasElement.height = overlayElement.offsetHeight; // Set the canvas size properly
    overlayElement.appendChild(canvasElement);

    /* global fabric */
    const fabricCanvas = new fabric.Canvas("overlayCanvas", {
      selection: false, // Disable default selection mode
    });

    let isDrawing = false;
    let existingRect = null;

    fabricCanvas.on("mouse:up", function (o) {
      if (isDrawing) {
        isDrawing = false; // Disable drawing mode after mouse up

        // Calculate position for PdfMenu
        const rect = existingRect.getBoundingRect();
        const pdfMenu = document.getElementById("area-selection-menu");
        pdfMenu.style.visibility = "visible";
        pdfMenu.style.left = rect.left + rect.width + "px";
        pdfMenu.style.top = rect.top + rect.height + "px";
      }
    });

    fabricCanvas.on("mouse:down", function (o) {
      if (existingRect || isDrawing) return; // If a rectangle already exists or is being drawn, do nothing

      const pointer = fabricCanvas.getPointer(o.e);
      existingRect = new fabric.Rect({
        left: pointer.x,
        top: pointer.y,
        width: 0,
        height: 0,
        fill: "transparent",
        stroke: "black",
        strokeWidth: 2,
      });
      fabricCanvas.add(existingRect);
      fabricCanvas.setActiveObject(existingRect);
      isDrawing = true; // Enable drawing mode on mouse down
    });

    fabricCanvas.on("mouse:move", function (o) {
      if (!isDrawing) return;
      const pointer = fabricCanvas.getPointer(o.e);
      if (existingRect) {
        existingRect.set({
          width: pointer.x - existingRect.left,
          height: pointer.y - existingRect.top,
        });
        existingRect.setCoords();
        fabricCanvas.renderAll();
      }
    });
  }

  // On resize, we need to set the pixel width/height of the canvas as big as the DOM size
  // The latter is the number of actual pixels of drawing surface
  // The former is the visible onscreen size of the canvas
  // IF these are not equal, a scaling is done and causes blur
  // We need to also set the fake scroll elements height to the whole documents height
  setUISizes(force = false) {
    // Has the viewport width changed?
    const [pxViewportW, pxViewPortH] = [
      this.elemContent.clientWidth,
      this.elemContent.clientHeight,
    ];

    // Hack - only change if difference is 4 px or more
    if (force || Math.abs(pxViewportW - this.pxViewportW) > 4) {
      // Set the viewport and canvas sizes
      this.pxViewportW = pxViewportW;
      this.pxViewPortH = pxViewPortH;

      // Set the canvas size to the viewport size times the dpi ratio
      this.pxCanvasW = this.pxViewportW * this.dpiRatio;
      this.pxCanvasH = this.pxViewPortH * this.dpiRatio;
      this.elemCanvas.width = this.pxCanvasW;
      this.elemCanvas.height = this.pxCanvasH;

      // Set the page height to the viewport width times the ratio of the offscreen height to the offscreen width
      this.pxPageH =
        ((this.pxViewportW * this.pxOffscreenH) / this.pxOffscreenW) | 0;

      // The scrollable range is the page height times the number of pages
      this.pxScrollableH = this.pxPageH * this.nPages;
      this.elemScroller.style.height = this.pxScrollableH + "px";

      // This is the amount you have to scroll to see the bottom of the page if the top of the page is at the top of the view
      const pxDefaultScroll = this.pxPageH - this.pxViewPortH;

      // The maximum value for fPage will need this pxDefaultScroll factored in
      // For example if the view is 50% of the PDF page and the document has 10 pages, the page number will be from 0 to 9.5
      this.fPageMax = this.nPages + pxDefaultScroll / this.pxPageH;
      // console.log("\n\n\n", {
      //   // elemCon: this.elemContent,
      //   pxViewportW,
      //   pxViewPortH,
      //   pxCanvasW: this.pxCanvasW,
      //   pxCanvasH: this.pxCanvasH,
      //   pageH: this.pxPageH,
      //   pxScrollableH: this.pxScrollableH,
      //   dpiRatio: this.dpiRatio,
      //   pxDefaultScroll,
      //   fPageMax: this.fPageMax,
      // });

      return true;
    }
    return false;
  }

  async renderPage(page, ctx) {
    const renderContext = {
      canvasContext: ctx,
      transform: [this.dpiRatio, 0, 0, this.dpiRatio, 0, 0],
      viewport: this.dctPDFViewPort,
    };

    return page.render(renderContext).promise;
  }

  async getTextContent(page, nPage) {
    return new Promise((resolve) => {
      page.getTextContent().then((textContent) => {
        this.dctCanvas[nPage][2] = textContent;
        resolve(true);
      });
    });
  }

  // Renders all the cached pages onto the offscreen canvases
  // Returns a promise array to wait on
  async renderCachedPages() {
    // Promises for page rendering if any
    const arrPromisePage = [],
      arrPromiseText = [];

    // If this is the very first time
    // Create cached canvases for the first time, grab their contexts, render pages onto them
    // Also save the the TextContent objects for each canvas
    if (!Object.keys(this.dctCanvas).length) {
      for (let k in this.dctPages) {
        // To ensure crispness, we need to render PDF pages at 300 DPI on an over-sized canvas
        // And then draw it onto the onscreen canvas, scaled down
        // getViewport returns the pixel size assuming a standard DPI of 72
        // To get the same physical size onscreen, our canvases will need to be
        // bigger by a factor of window.devicePixelRatio
        this.pxOffscreenW = (this.dctPDFViewPort.width * this.dpiRatio) | 0;
        this.pxOffscreenH = (this.dctPDFViewPort.height * this.dpiRatio) | 0;
        const canvas = new OffscreenCanvas(
          this.pxOffscreenW,
          this.pxOffscreenH
        );
        this.dctCanvas[k] = [
          canvas,
          canvas.getContext("2d", { alpha: false }),
          null,
        ];
      }

      // Loop through and render all pages, saving the promises, also get the texts
      for (let iPage in this.dctPages) {
        const p = this.renderPage(
          this.dctPages[iPage],
          this.dctCanvas[iPage][1]
        );
        arrPromisePage.push(p);

        const pText = this.getTextContent(this.dctPages[iPage], iPage);
        arrPromiseText.push(pText);
      }
    } // Canvases already exist
    else {
      // We have a dict of pages that have loaded
      // Some of those pages have already been rendered to their canvases, some not

      // Get the page numbers for the pages that newly need to be rendered
      const arrNewPages = [];
      for (const iPage in this.dctPages) {
        if (!(iPage in this.dctCanvas)) {
          arrNewPages.push(iPage);
        }
      }

      // Extract the canvases that are for pages not in the page cache
      const arrCanvasFree = [];
      for (const iPage in this.dctCanvas) {
        if (!(iPage in this.dctPages)) {
          arrCanvasFree.push(this.dctCanvas[iPage]);
          delete this.dctCanvas[iPage];
        }
      }

      // Iterate over the new page numbers, render onto the free canvases
      //assert(arrNewPages.length === arrCanvasFree.length)
      //if(arrNewPages.length) console.log(`Cached pages: ${arrNewPages.length} ${Date.now()/1000}`);
      let n = 0;
      for (const iPage of arrNewPages) {
        // Render the new pages and save the promises
        const pRender = this.renderPage(
          this.dctPages[iPage],
          arrCanvasFree[n][1]
        );
        arrPromisePage.push(pRender);

        const pText = this.getTextContent(this.dctPages[iPage], iPage);
        arrPromiseText.push(pText);

        // Save the canvas to the cache, keyed for this page number
        this.dctCanvas[iPage] = arrCanvasFree[n];
        ++n;
      }
    }

    // Wait for all the page promises to resolve
    await Promise.all(arrPromisePage);

    // Wait for all the text promises to resolve
    await Promise.all(arrPromiseText);
  }

  // Grabs upto N pages from the current page no
  async preloadPages() {
    const nCurPage = this.fPage | 0;
    const nHalf = (this.nCached / 2) | 0;
    let nStart = nCurPage - nHalf;
    let nEnd = nCurPage + (this.nCached - nHalf);

    // Clamp this range from top so its within 0 to N
    if (nStart < 0) {
      nEnd += -nStart;
      nStart = 0;
    }

    // Clamp this range from bottom so its within 0 to N
    if (nEnd > this.nPages) {
      const d = nEnd - this.nPages;
      nEnd -= d;
      nStart -= d;
      if (nStart < 0) nStart = 0;
    }

    //console.log(`Cacheable pages ${nStart}-${nEnd}`)

    // Cache all the pages in this range if not already
    let dctOldPages = this.dctPages;
    this.dctPages = {};

    // array of promises for rendering new pages, and their numbers
    let arrPromise = [],
      arrNumNew = [];

    for (let n = nStart; n < nEnd; ++n) {
      // If we had the page before, move it to new, remove from old
      if (n in dctOldPages) {
        this.dctPages[n] = dctOldPages[n];
      } else {
        // Defer each new page to load, saving page num too
        arrNumNew.push(n);

        // PDF lib uses 1 based indexing
        arrPromise.push(this.pdf.getPage(n + 1));
      }
    }

    //console.log(`Already found ${Object.keys(this.dctPages)}`)
    //console.log(`New pages ${arrNumNew}`)

    return new Promise((resolve) => {
      // Wait for all pages to load and save to cache
      Promise.all(arrPromise).then((pages) => {
        // Save each new rendered page to cache
        for (let i = 0; i < pages.length; ++i)
          this.dctPages[arrNumNew[i]] = pages[i];

        // Grab the viewport (assume all pages are same size)
        if (!this.dctPDFViewPort) {
          this.dctPDFViewPort = this.dctPages[arrNumNew[0]].getViewport({
            scale: 1,
          });
        }

        //console.log(`Loaded ${Object.keys(this.dctPages)}`)

        resolve(true);
      });
    });
  }

  renderViewPort() {
    if (!this.pdf) return;

    this.setUISizes();

    // Current view canvas width (height never changes)
    const viewW = (this.fScale * this.pxCanvasW) | 0;
    const viewH = this.pxCanvasH | 0;

    // Source offscreen canvas dimensions
    const sw = this.pxOffscreenW;
    const sh = this.pxOffscreenH;

    // Destination dims are based on the viewport width
    const dw = viewW;
    const dh = ((dw * sh) / sw) | 0;

    let iPage = this.fPage | 0;
    let fFrac = this.fPage - iPage;

    // The first pages content will be drawn at a y negatively offset by the scroll fraction
    // The first and last pages content may be drawn beyond the canvas bounds, but the canvas clip automatically
    let viewY = (0 - fFrac * dh) | 0;

    // Render each page starting from current until the view canvas is filled
    // Due to rounding errors, the loop may try to access a page beyond the end, so handle that too
    while (viewY < viewH && iPage < this.nPages && this.dctCanvas[iPage]) {
      // Ref :     drawImage(img, sx, sy, sw, sh, dx, dy, dw, dh)
      const img = this.dctCanvas[iPage][0];
      this.ctxMain.drawImage(img, 0, 0, sw, sh, 0, viewY, dw, dh);
      viewY += dh;
      iPage++;
    }

    // Draw the rectangles
    iPage = this.fPage | 0;
    viewY = 0 - fFrac * dh;
    while (viewY < viewH && iPage < this.nPages && this.dctCanvas[iPage]) {
      this.ctxMain.strokeRect(0, viewY, dw, dh);
      viewY += dh;
    }

    // Render the text layer elements
    this.renderTextLayer();
  }

  renderTextLayer() {
    let iPage = this.fPage | 0;

    if (!this.elemStyle) {
      this.elemStyle = document.createElement("style");
      this.elemStyle.innerHTML = sStyles;
      document.getElementsByTagName("head")[0].appendChild(this.elemStyle);
    }

    // Calculate the scale between the content width and the viewport width
    const fScale = this.pxViewportW / this.dctPDFViewPort.width;

    // Calculate the scrolled amount of the current page
    const fFrac = this.fPage - iPage;
    let fScroll = fFrac * this.pxPageH;
    let fOffsetY = -fScroll;

    const dctElemSpans = {};

    // TODO make array of tasks for renderTextLayer
    const dctTextTasks = {};

    if (iPage >= this.nPages || !this.dctCanvas[iPage]) return;

    // Render each page that's visible
    for (let i = 0; i < 2; ++i, ++iPage, fOffsetY += this.pxPageH) {
      if (iPage >= this.nPages) continue;

      // Get a viewport from the page with this scale
      const dctViewPortText = this.dctPages[iPage].getViewport({
        scale: fScale,
        offsetY: fOffsetY,
      });

      const oText = this.dctCanvas[iPage][2];
      if (!oText) continue;

      // Pass the data to the method for rendering of text over the pdf canvas.
      this.elemText.innerHTML = "";

      // Save each pages element pointer to concat later
      dctElemSpans[iPage] = [];
      const elems = dctElemSpans[iPage];

      //debugger;
      //console.log(`Rendering text layer for page ${iPage}: ${oText} elements`)
      const task = pdfjsLib.renderTextLayer({
        textContent: oText,
        container: this.elemText,
        viewport: dctViewPortText,
        textDivs: elems,
      });

      dctTextTasks[iPage] = task.promise;

      // console.log("dctElemSpans : ", {
      //   elems,
      //   dctViewPortText,
      //   textContent: oText,
      // });
      // elems.forEach((ele, idx) => console.log(`new : ${idx}`, ele.innerHTML));
    }

    // Wait for all text layers to render
    Promise.all(Object.values(dctTextTasks)).then(() => {
      // Collect all the elems from multiple pages into one array
      this.arrElemSpans = [];
      for (iPage in dctTextTasks) {
        // Set all the text elements class to pdf-text-span, hide the rotated ones
        for (let i = 0; i < dctElemSpans[iPage].length; ++i) {
          const elem = dctElemSpans[iPage][i];
          elem.className = "pdf-text-span";

          if (elem.style.transform.substr(0, 6) === "rotate") {
            elem.style.display = "none";
          }
        }

        this.arrElemSpans.push(...dctElemSpans[iPage]);
      }

      // Get all the sorted non-overlapping matches for the currently visible page text
      this.getMatchData();

      // Split the spans and save the highlight information per span that matches
      this.splitSpans();

      // Hide the selection box if any
      console.log("sec");
      this.doClearSelection();
    });
  }

  // Renders the current page into viewport
  async render(bForceSizeCalc = false) {
    if (!this.pdf) return;

    //console.log('Rendering')

    // Has the page number changed? if so we may need to render PDF pages to offscreen canvas
    if (this.iRenderedPage !== (this.fPage | 0)) {
      // Preload page cache
      //console.log('Preload')
      await this.preloadPages();

      // Render the cached pages and wait for it to be done
      //console.log('Render cached pages')
      await this.renderCachedPages();

      this.iRenderedPage = this.fPage | 0;
    }

    // Now render onto the actual page
    //console.log('Render viewport')
    this.renderViewPort(bForceSizeCalc);
  }
}
