import React, { useEffect, useState } from "react";
import { Button } from "../Button/Button";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { FaTrashCan } from "react-icons/fa6";
import toast from "react-hot-toast";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "../Dropdown/Dropdown";
import { getAllDomains } from "../../utils";
import { Input } from "../Input/Input";

const UploadPdfOverlay = ({ toggleUploadOverlay }) => {
  const [file, setFile] = useState(null);
  const [domainsList, setDomainsList] = useState([]);
  const [domain, setDomain] = useState("");
  const [title, setTitle] = useState("");
  const [supplementalFiles, setSupplementalFiles] = useState([]);

  const handleSupplementalFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    setSupplementalFiles([...supplementalFiles, ...newFiles]);
  };

  const handleSupplementalFileRemove = (fileIndex) => {
    const newFiles = supplementalFiles.filter(
      (ele, index) => index !== fileIndex
    );
    setSupplementalFiles(newFiles);
  };

  const handleFileUpload = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  useEffect(() => {
    (async () => {
      const domains = await getAllDomains();
      setDomainsList(domains);
      setDomain(domains[0]);
    })();
  }, []);

  const handleDomainChange = (newLabel) => {
    const newDomain = domainsList.find((ele) => ele.label === newLabel);
    setDomain(newDomain);
  };

  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (title?.trim().length < 1) {
      toast.error("Please enter a valid title");
      return;
    }
    if (!file) {
      toast.error("Please add a file");
      return;
    }
    // if (!domain.trim()) {
    //   toast.error("Please add enter the domain");
    //   return;
    // }
    const formData = new FormData();
    formData.append("domain", domain.name);
    formData.append("title", title);
    formData.append("file", file);

    supplementalFiles.forEach((file, index) => {
      formData.append(`supplemental_files`, file);
    });

    const promise = axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/upload_resources`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    toggleUploadOverlay();

    toast.promise(promise, {
      loading: "Uploading",
      success: "File uploaded successfully",
      error: "Error uploading file",
    });

    const response = await promise;

    const resourceId = response?.data;

    navigate(`/`);
  };
  return (
    <div className="overlay flex justify-center items-center">
      <div className="flex flex-col justify-center items-center gap-3 bg-[#f0f0f0] p-3 rounded relative w-[400px]">
        <div
          className="absolute top-1 right-1 cursor-pointer"
          onClick={toggleUploadOverlay}
        >
          <IoMdCloseCircleOutline />
        </div>
        <h3 className="text-center font-bold text-2xl mb-3">Upload Files</h3>

        <div className="w-[100%]">
          <p className="text-lg font-bold mb-2">Title of the paper</p>
          <div className="flex justify-center">
            <Input
              placeHolder="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            ></Input>
          </div>
        </div>
        {/* <div className="w-[100%]">
          <p className="text-lg font-bold mb-2">Domain</p>
          <div className="flex justify-center">
            <Dropdown
              options={domainsList.map((ele) => ele.label)}
              value={domain.label}
              setValue={handleDomainChange}
            />
          </div>
        </div> */}
        <div className="w-[100%]">
          <p className="text-lg font-bold mb-2">Research Paper</p>

          {file ? (
            <div className="flex gap-4 justify-center">
              <p>{file?.name}</p>
              <button onClick={() => setFile(null)} className="">
                <FaTrashCan />
              </button>
            </div>
          ) : (
            <p className="text-center">No file chosen</p>
          )}
          <div className="my-4 flex justify-center">
            <label
              htmlFor="fileInput"
              className="cursor-pointer py-2 px-4 rounded text-neutral-700 border border-neutral-700 hover:bg-neutral-700 hover:text-white text-center "
            >
              {/* <BsCloudUploadFill /> */}
              {file ? "Replace document" : "Choose Document"}
            </label>
            <input
              id="fileInput"
              type="file"
              className="hidden"
              accept=".pdf"
              onChange={handleFileUpload}
            />
          </div>
        </div>
        {/* <input
          value={domain}
          onChange={(e) => setDomain(e?.target?.value)}
          placeholder="Domain"
          className="p-2"
        /> */}

        {/* <div className="w-[100%]">
          <p className="text-lg font-bold mb-2">Supplemental files</p>
          {supplementalFiles?.length > 0 ? (
            <div className="flex flex-col gap-4">
              {supplementalFiles.map((file, index) => (
                <div key={index} className="flex items-center">
                  <span className="mr-2">{file.name}</span>
                  <button
                    onClick={() => handleSupplementalFileRemove(index)}
                    className=""
                  >
                    <FaTrashCan />
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-center">No files chosen</p>
          )}
          <div className="my-4 flex justify-center">
            <input
              id="multiFileInput"
              type="file"
              onChange={handleSupplementalFileChange}
              className="mb-4 hidden"
              multiple
            />
            <label
              htmlFor="multiFileInput"
              className="cursor-pointer py-2 px-4 rounded text-neutral-700 border border-neutral-700 hover:bg-neutral-700 hover:text-white text-center"
            >
              Choose Files
            </label>
          </div>
        </div> */}
        <Button onClick={handleSubmit}>Upload</Button>
      </div>
    </div>
  );
};

export { UploadPdfOverlay };
