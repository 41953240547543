import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PDFList, Button, ProcessingOverlay } from "../../components";

const Home = () => {
  const navigate = useNavigate();
  const handleUploadRedirect = () => {
    navigate("/upload");
  };

  const [showProcessingOverlay, setShowProcessingOverlay] = useState(false);
  const [processingData, setProcessingData] = useState(false);
  const toggleShowProcessingOverlay = (data) => {
    setProcessingData(data)
    setShowProcessingOverlay((prev) => !prev);
  };
  return (
    
    <div className="mx-3">
      {showProcessingOverlay && (
        <ProcessingOverlay toggleOverlay={toggleShowProcessingOverlay} processingData={processingData} />
      )}
      <h1 className="my-3 text-center font-bold text-3xl">Interactive PDF</h1>
      <PDFList toggleShowProcessingOverlay={toggleShowProcessingOverlay}/>
      <div className="w-full my-5 mx-auto text-center">
        <Button onClick={handleUploadRedirect}>Upload New File</Button>
      </div>
    </div>
  );
};

export { Home };
