import React, { useCallback, useEffect, useState } from "react";
import { Button } from "../Button/Button";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  setVideoShots,
  updateVideoShotTitle,
  updateVideoBlockVoiceover,
  setVideoShotsSchema,
  deleteVideoShot,
  deleteVideoBlock,
  updateVideoBlockDisplayText,
  addVideoShot,
} from "../../features";
import { TextArea } from "../TextArea/TextArea";
import { Input } from "../Input/Input";
import { nanoid } from "@reduxjs/toolkit";
// import { updateShotInputValue } from "../../features/videoShots/videoShotsSlice";
import axios from "axios";
import Spinner from "react-spinkit";
import { useNavigate, useParams } from "react-router-dom";
import { DeckShotItem } from "../DeckShotItem/DeckShotItem";

const dummyVideoShotsList = {
  id: "1234",
  shots: [
    {
      id: "667901259cef90461ad6c97d",
      title: "Introduction",
      shot_type: "voiceoverTextShot",
      description:
        "A display-voiceover type shot. May have multiple such display-voiceover blocks ",
      blocks: [
        {
          id: "1",
          type: "text",
          displayText: "hey welcome",
          voiceoverText: "welcome ",
        },
        {
          id: "2",
          type: "text",
          displayText: "The is the second block",
          voiceoverText: "Voice over for the second block",
        },
      ],
    },
    {
      id: "667901259cef90461ad6c97d3",
      title: "Page View Shot example",
      shot_type: "pageviewShot",
      description:
        "A display-voiceover type shot. May have multiple such display-voiceover blocks ",
      blocks: [
        {
          id: "12",
          type: "image",
          url: "https://dummyimage.com/300",
          bBox: [],
          pageNumber: -1,
          voiceoverText: "welcome ",
        },
        {
          id: "23",
          type: "image",
          url: "https://dummyimage.com/300",
          bBox: [],
          pageNumber: -1,
          voiceoverText: "Voice over for the second block",
        },
      ],
    },
  ],
};

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

const DeckShotsPanel = () => {
  const dispatch = useDispatch();

  // const [shotSchemas, setShotSchemas] = useState([
  //   {
  //     id: "667901259cef90461ad6c97d",
  //     title: "Voiceover text block",
  //     description:
  //       "A display-voiceover type shot. May have multiple such display-voiceover blocks ",
  //     shot_level_fields: {
  //       heading: {
  //         type: "string",
  //         inputType: "input",
  //         label: "Heading",
  //         description: "A title to show for this shot",
  //         isRequired: true,
  //         placeholder: "Give a title for this shot",
  //       },
  //     },
  //     type: "object",
  //     shot_type: "voiceover",
  //     required: [],
  //     recreatable_fields: {
  //       display_text: {
  //         type: "string",
  //         inputType: "textarea",
  //         label: "Display Text",
  //         description: "Text to be displayed in the video",
  //         isRequired: true,
  //         placeholder: "What text to show in the video",
  //       },
  //       voiceover_text: {
  //         type: "string",
  //         inputType: "textarea",
  //         label: "Voiceover Text",
  //         description: "Corressponding audio to be played",
  //         isRequired: true,
  //         placeholder: "Narration for the above display text",
  //       },
  //     },
  //   },
  // ]);

  const [showLoader, setShowLoader] = useState(true);
  // const [scriptData, setScriptData] = useState({});

  const { resourceId } = useParams();

  const navigate = useNavigate();

  // loader :

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 0);

    return () => clearTimeout(timer);
  }, []);

  const { shotsData } = useSelector((state) => state.videoShots);

  const handleTitleUpdate = async ({ shotId, newTitle }) => {
    dispatch(updateVideoShotTitle({ shotId, newTitle }));
    // const res = await axios.put(
    //   `${process.env.REACT_APP_BACKEND_BASE_URL}/update_deck_script_shot`,
    //   {
    //     script_id: scriptData?.id,
    //     id: shotId,
    //     title: newTitle,
    //   }
    // );
    // setScriptData(res?.data);
  };

  const handleVoiceoverUpdate = async ({
    shotId,
    blockId,
    newVoiceoverText,
  }) => {
    dispatch(updateVideoBlockVoiceover({ shotId, blockId, newVoiceoverText }));
    // const res = await axios.put(
    //   `${process.env.REACT_APP_BACKEND_BASE_URL}/update_deck_script_block`,
    //   {
    //     script_id: scriptData?.id,
    //     id: blockId,
    //     shot_id: shotId,
    //     voiceoverText: newVoiceoverText,
    //   }
    // );
    // setScriptData(res?.data);
  };

  const handleDisplayTextUpdate = async ({
    shotId,
    blockId,
    newDisplayText,
  }) => {
    dispatch(updateVideoBlockDisplayText({ shotId, blockId, newDisplayText }));
  };

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  // const debouncedHandleVoiceoverUpdate = debounce(handleVoiceoverUpdate, 5000);
  // const debouncedHandleVoiceoverUpdate = useCallback(
  //   debounce((value) => handleVoiceoverUpdate(value), 300),
  //   []
  // );

  const handleDeleteShot = async (id) => {
    dispatch(deleteVideoShot({ id }));
    // const res = await axios.delete(
    //   `${process.env.REACT_APP_BACKEND_BASE_URL}/delete_deck_script_shot`,
    //   {
    //     data: {
    //       script_id: scriptData?.id,
    //       id: id,
    //     },
    //   }
    // );
    // setScriptData(res?.data);
  };

  const handleDeleteBlock = async (shotId, blockId) => {
    dispatch(deleteVideoBlock({ shotId, blockId }));
    // const res = await axios.delete(
    //   `${process.env.REACT_APP_BACKEND_BASE_URL}/delete_deck_script_block`,
    //   {
    //     data: {
    //       script_id: scriptData?.id,
    //       shot_id: shotId,
    //       id: blockId,
    //     },
    //   }
    // );
    // setScriptData(res?.data);
  };

  useEffect(() => {
    (async () => {
      // const result = await axios.get(
      //   `${process.env.REACT_APP_BACKEND_BASE_URL}/get_all_shot_schema`
      // );
      // setShotSchemas(result?.data);
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/get_deck_template_data`,
        {
          resource_id: resourceId,
        }
      );
      dispatch(setVideoShots({ newShotsData: res?.data }));
      // setScriptData(res?.data);
      // setVideoShots(res?.data);
    })();

    // dispatch(setVideoShots({ newShotsData: dummyVideoShotsList }));
  }, []);

  const addToShotList = (shot) => {
    //   const shotLevelFields = [];
    //   for (const field in shot?.shot_level_fields) {
    //     shotLevelFields.push({
    //       ...shot?.shot_level_fields?.[field],
    //       key: field,
    //       id: nanoid(),
    //       value: "",
    //     });
    //   }
    //   const recreatableFields = [];
    //   const temp = [];
    //   for (const field in shot?.recreatable_fields) {
    //     temp.push({
    //       ...shot?.recreatable_fields?.[field],
    //       key: field,
    //       id: nanoid(),
    //       value: "",
    //     });
    //   }
    //   recreatableFields.push(temp);
    //   dispatch(
    //     addVideoShot({
    //       shotToAdd: {
    //         recreatableFields,
    //         shotLevelFields,
    //         id: nanoid(),
    //         shot_type: shot?.shot_type,
    //       },
    //     })
    //   );
  };

  const handleGenerateDeck = async () => {
    // console.log({ shotsData });
    await axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/generate_deck`,
      {
        resource_id: resourceId,
        shots: shotsData?.shots,
      }
    );
    navigate("/outputs/"+resourceId);
  };

  const handleAddNewSection = async () => {
    dispatch(addVideoShot());
    // console.log({ shotsData });
    // const res = await axios.post(
    //   `${process.env.REACT_APP_BACKEND_BASE_URL}/add_new_deck_section`,
    //   {
    //     script_id: scriptData?._id,
    //   }
    // );
    // setScriptData(res?.data);
  };

  // const jsonToComponent = ({ shotId, ele, fieldType, blockId }) => {
  //   const { label, placeHolder, description, id, value } = ele;
  //   switch (ele.inputType) {
  //     case "textarea":
  //       return (
  //         <div key={id}>
  //           <p className="text-xl mb-2" placeHolder={placeHolder}>
  //             {label}
  //           </p>
  //           <TextArea
  //             value={value}
  //             placeHolder={placeHolder}
  //             onChange={(e) =>
  //               handleValueUpdate({
  //                 shotId,
  //                 fieldType,
  //                 blockId,
  //                 newValue: e?.target?.value,
  //               })
  //             }
  //           />
  //         </div>
  //       );
  //     case "input":
  //       return (
  //         <div key={id}>
  //           <p className="text-xl mb-2">{label}</p>
  //           <Input
  //             classes={" w-[100%] p-3 "}
  //             placeHolder={placeHolder}
  //             value={value}
  //             onChange={(e) =>
  //               handleValueUpdate({
  //                 shotId,
  //                 fieldType,
  //                 blockId,
  //                 newValue: e?.target?.value,
  //               })
  //             }
  //           />
  //         </div>
  //       );

  //     default:
  //       return null;
  //   }
  // };

  // const handleValueUpdate = ({ shotId, fieldType, blockId, newValue }) => {
  //   dispatch(updateShotInputValue({ shotId, fieldType, blockId, newValue }));
  // };

  // const handleAddBlock = (id, blockType) => {
  //   const schema = shotSchemas.filter(
  //     (ele) => ele.shot_type === blockType
  //   )?.[0];
  //   const temp = [];
  //   for (const field in schema?.recreatable_fields) {
  //     temp.push({
  //       ...schema?.recreatable_fields?.[field],
  //       id: nanoid(),
  //       key: field,
  //       value: "",
  //     });
  //   }
  //   dispatch(addBlockToShot({ shotId: id, newBlock: temp }));
  // };

  return (
    <>
      {showLoader ? (
        <div className="flex flex-col gap-5 justify-center items-center h-[100%] mx-[20px]">
          <Spinner name="cube-grid" color="#4dabe1" />
          <p className="text-xl font-semibold">
            Generating video scripts from clinical trial summary template, it
            might take a few minutes
          </p>
        </div>
      ) : (
        <div>
          {/* <div className="pb-5 border-b-black">
            <h2 className="text-3xl font-bold my-2 py-2">Available Shots</h2>

            {shotSchemas?.map((ele) => (
              <Button onClick={() => addToShotList(ele)}>{ele?.title}</Button>
            ))}
          </div> */}
          <div className="flex flex-col gap-3 my-4 bg-blue-200 p-4 rounded-lg">
            <div className="pb-1 border-b-black self-end">
            <Button onClick={handleGenerateDeck}>Generate Deck</Button>
              {/* <Button onClick={() => handleAddNewSection()}>
                Add New Section
              </Button> */}
            </div>
            {shotsData?.shots?.map((shot) => {
              const { id: shotId, shot_type, blocks, title } = shot;

              return (
                <div
                  className="flex flex-col gap-3 bg-blue-100 rounded-t-lg"
                  key={shotId}
                >
                  <DeckShotItem
                    title={title}
                    blocks={blocks}
                    shotId={shotId}
                    handleTitleUpdate={handleTitleUpdate}
                    // handleVoiceoverUpdate={handleVoiceoverUpdate}
                    handleDeleteShot={handleDeleteShot}
                    handleDeleteBlock={handleDeleteBlock}
                    handleDisplayTextUpdate={handleDisplayTextUpdate}
                  />
                </div>
              );
            })}
          </div>
          <div className="flex justify-end m-2">
            <Button onClick={handleGenerateDeck}>Generate Deck</Button>
          </div>
        </div>
      )}
    </>
  );
};

export { DeckShotsPanel };
