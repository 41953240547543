import axios from "axios";
// import { saveAs } from "file-saver";
import toast from "react-hot-toast";
import { pdfView } from "./components/PDFRenderer/PDFRenderer";

const downloadFile = async (
  fileName,
  fileExtension,
  content = "# Test content\n\n\n## sub\nconetbf\n\n\nsec-3"
) => {
  try {
    // Make a POST request to the FastAPI endpoint
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/generate_pdf`,
      { markdown_content: content },
      {
        responseType: "blob",
      }
    );

    // Create a blob from the response data
    const blob = new Blob([response.data], { type: "application/pdf" });

    // Create a link and trigger a download
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "output.pdf";
    link.click();
  } catch (error) {
    // Handle errors
    console.error("Error:", error.message);
  }
};

const instructionParser =
  /\[{2}(?<context>.*?)\]{2}[\s\r\n]*\({2}(?<command>.*?)\){2}/gs;

const generate = async (domain, taskInput, taskInstruction) => {
  try {
    const result = await axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/openai_chat`,
      {
        domain: domain,
        content: taskInput,
        instructions: taskInstruction,
      }
    );

    return result?.data;
  } catch (error) {
    console.error("Error in generate:", error);
  }
};

const getReplacement = async (
  content,
  taskInput,
  taskInstruction,
  domain,
  historySize = 1000
) => {
  const augment = `<mark>${taskInput}</mark>`;
  const augmentedTaskInput = content.slice(-historySize) + augment;
  const replacement = await generate(
    domain,
    augmentedTaskInput,
    taskInstruction
  );
  return replacement;
};

const processInput = async (chunk, domain, historySize = 1000) => {
  let processed = "";
  let lastStop = 0;

  for (const m of chunk.matchAll(instructionParser)) {
    const [fullMatch, contextStart, commandStart] = m;
    const start = m.index;
    const stop = start + fullMatch.length;
    processed += chunk.slice(lastStop, start);
    lastStop = stop;
    let newReplacement = await getReplacement(
      processed,
      m.groups.context,
      m.groups.command,
      domain,
      historySize
    );
    processed += newReplacement;
  }

  processed += chunk.slice(lastStop);
  return processed;
};

const handlePlugin = async ({
  pluginType,
  domain,
  userInput,
  context,
  instruction,
  filesList,
}) => {
  switch (pluginType) {
    case "rewrite":
      return await rewriteContent({
        domain,
        user_input: userInput,
        context,
        instruction,
      });
    case "generate_image":
      const url = await generateImage({
        domain,
        user_input: userInput,
        context,
        instruction,
      });
      return `![title](${url})`;
    case "instruct":
      return await instruct_LLM({
        domain,
        user_input: userInput,
        context,
        instruction,
      });
    // case "RAG Instruct":
    //   const filesURL = filesList
    //     ?.filter((file) => file?.isChosen)
    //     ?.map((file) => file?.url);
    //   return await RAG_Instruct({
    //     domain,
    //     user_input: userInput,
    //     context,
    //     instruction,
    //     filesList: filesURL,
    //   });
    case "generate_code":
      return await generateCode({
        domain,
        user_input: userInput,
        context,
        instruction,
      });
    default:
      toast.error("Something went wrong");
      return;
  }
};

const instruct_LLM = async (userInput) => {
  const promise = axios.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/instruct`,
    { ...userInput }
  );

  toast.promise(promise, {
    loading: "Processing",
    success: "Done",
    error: "Something went wrong",
  });

  try {
    const result = await promise;
    return result?.data;
  } catch (error) {
    console.error(error);
  }
};

const RAG_Instruct = async (userInput) => {
  const promise = axios.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/rag_instruct`,
    { ...userInput }
  );

  toast.promise(promise, {
    loading: "Processing",
    success: "Done",
    error: "Something went wrong",
  });

  try {
    const result = await promise;
    return result?.data;
  } catch (error) {
    console.error(error);
  }
};

const rewriteContent = async (userInput) => {
  const promise = axios.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/rewrite_content`,
    { ...userInput }
  );

  toast.promise(promise, {
    loading: "Processing",
    success: "Done",
    error: "Something went wrong",
  });

  try {
    const result = await promise;
    return result?.data;
  } catch (error) {
    console.error(error);
  }
};

async function getImageBlobFromURL(url) {
  //Fetch image data from url
  const imageData = await fetch(url);
  //Create blob of image data
  const imageBlob = await imageData.blob();
  return imageBlob;
}

const generateImage = async (userInput) => {
  const promise = axios.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/generate_image`,
    { ...userInput }
  );

  toast.promise(promise, {
    loading: "Processing",
    success: "Done",
    error: "Something went wrong",
  });

  try {
    const result = await promise;
    // const base64 = await getImageBlobFromURL(result);
    return result?.data;
  } catch (error) {
    console.error(error);
  }
};

const generateCode = async (userInput) => {
  const promise = axios.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/generate_code`,
    { ...userInput }
  );

  toast.promise(promise, {
    loading: "Processing",
    success: "Done",
    error: "Something went wrong",
  });

  try {
    const result = await promise;
    // const base64 = await getImageBlobFromURL(result);
    return result?.data;
  } catch (error) {
    console.error(error);
  }
};

const smartGenerate = async (input) => {
  const promise = axios.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/smart_generate`,
    {
      user_input: input,
    }
  );

  toast.promise(promise, {
    loading: "Processing",
    success: "Done",
    error: "Something went wrong",
  });

  try {
    const result = await promise;
    // const base64 = await getImageBlobFromURL(result);
    return result?.data;
  } catch (error) {
    console.error(error);
  }
};

const processSectionsList = (document, dimension) => {
  const { fFrac, pageH: height, pageW: width } = pdfView.getPageData();
  // const { height, width } = dimension;
  const topDelta = height * fFrac;
  let res = document.map((section) => {
    const { blocks } = section;

    const normalizedBlocks = blocks.map((block) => {
      block = { ...block };
      block.context = { ...block.context };
      if (block.type === "Text") {
        if (block.context.boundingBox.length === 4) {
          const [a, b, c, d] = block.context.boundingBox;
          block.context.boundingBox = noramlizeBB(
            [a, b + topDelta, c, d + topDelta],
            height,
            width
          );
        }
      } else if (block.type === "Arrow") {
        if (block.context.start && block.context.end) {
          const { start, end } = block.context;
          block.context.start = normalizePoint(
            start.x,
            start.y + topDelta,
            height,
            width
          );
          block.context.end = normalizePoint(
            end.x,
            end.y + topDelta,
            height,
            width
          );
        }
      } else if (block.type === "Rectangle") {
        if (block.context.topLeft && block.context.bottomRight) {
          const { topLeft, bottomRight } = block.context;
          block.context.topLeft = normalizePoint(
            topLeft.x,
            topLeft.y + topDelta,
            height,
            width
          );
          block.context.bottomRight = normalizePoint(
            bottomRight.x,
            bottomRight.y + topDelta,
            height,
            width
          );
        }
      }
      return block;
    });

    return { ...section, blocks: normalizedBlocks };
  });

  return res;
};

const generateVideo = async (document, dimension) => {
  const updatedDocument = processSectionsList(document, dimension);
  const promise = axios.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/generate_video`,
    {
      sections: updatedDocument,
    }
  );

  toast.promise(promise, {
    loading: "Processing",
    success: "Done",
    error: "Something went wrong",
  });

  try {
    const result = await promise;
    // const base64 = await getImageBlobFromURL(result);
    return result?.data;
  } catch (error) {
    console.error(error);
  }
};

function findRectangleCorners(points) {
  // Extracting points
  const { tl, tr, bl, br } = points;

  // Sorting points based on x coordinate
  const sortedX = [tl, tr, bl, br].sort((a, b) => a.x - b.x);

  // Determining topLeft and bottomLeft based on y coordinate of sortedX
  const [left1, left2] = sortedX;
  const topLeft = left1.y < left2.y ? left1 : left2;
  const bottomLeft = left1.y < left2.y ? left2 : left1;

  // Sorting remaining points (topRight and bottomRight) based on y coordinate
  const sortedY = [tl, tr, bl, br].sort((a, b) => a.y - b.y);

  // Determining topRight and bottomRight based on x coordinate of sortedY
  const [right1, right2] = sortedY;
  const topRight = right1.x > right2.x ? right1 : right2;
  const bottomRight = right1.x > right2.x ? right2 : right1;

  return { tl: topLeft, tr: topRight, bl: bottomLeft, br: bottomRight };
}

// const getArrowPoints = (metaData, parentRef) => {
//   const { start, end } = metaData;
//   const normalizedStart = normalizePoint(start.x, start.y, parentRef);
//   const normalizedEnd = normalizePoint(end.x, end.y, parentRef);
//   return { start: normalizedStart, end: normalizedEnd };
// };

const normalizePoint = (x, y, height, width) => {
  return { x: x / width, y: y / height };
};

const noramlizeBB = (bb, height, width) => {
  const [a, b, c, d] = bb;
  return [a / width, b / height, c / width, d / height];
};

const getToolMetadata = (selectedTool, object) => {
  switch (selectedTool) {
    case "Rectangle":
      return object?.aCoords;
    case "Arrow":
      return findArrowPoints(object);
    default:
      return object;
  }
};

function findArrowPoints(arrowObject) {
  // Extracting properties
  // const { left, top, angle, flipX, flipY, skewX, skewY } = arrowObject;
  // const { width, height } = arrowObject.getBoundingRect();
  // // Calculating coordinates of the arrow head
  // const arrowHeadX = left + (width / 2) * (flipX ? -1 : 1);
  // const arrowHeadY = top + (height / 2) * (flipY ? -1 : 1);
  // // Calculating start and end points based on angle and skew
  // const startX =
  //   arrowHeadX - (width / 2) * Math.cos(angle) - (height / 2) * Math.sin(angle);
  // const startY =
  //   arrowHeadY - (width / 2) * Math.sin(angle) + (height / 2) * Math.cos(angle);
  // const endX =
  //   arrowHeadX + (width / 2) * Math.cos(angle) - (height / 2) * Math.sin(angle);
  // const endY =
  //   arrowHeadY + (width / 2) * Math.sin(angle) + (height / 2) * Math.cos(angle);
  // // Adjusting for skew
  // const skewAdjustedStartX = startX + (height / 2) * Math.tan(skewX);
  // const skewAdjustedStartY = startY + (width / 2) * Math.tan(skewY);
  // const skewAdjustedEndX = endX + (height / 2) * Math.tan(skewX);
  // const skewAdjustedEndY = endY + (width / 2) * Math.tan(skewY);
  // return {
  //   start: { x: skewAdjustedStartX, y: skewAdjustedStartY },
  //   end: { x: skewAdjustedEndX, y: skewAdjustedEndY },
  // };
}

const uploadImage = async (image) => {
  const formData = new FormData();
  formData.append("file", image);

  const promise = axios.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/upload_image`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  toast.promise(promise, {
    loading: "Processing",
    success: "Done",
    error: "Something went wrong",
  });

  try {
    const result = await promise;
    // const base64 = await getImageBlobFromURL(result);
    return result?.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const getPrompts = async (params) => {
  const res = await axios.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/get_prompts`,
    {
      ...params,
    }
  );
  return res?.data;
};

const processUserQuery = async ({
  type,
  text,
  domain,
  context,
  page,
  resourceId,
  bbox,
}) => {
  const res = await axios.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/process_user_query`,
    {
      type,
      text,
      domain,
      context,
      page,
      resourceId,
      bbox,
    }
  );
  return res?.data;
};

const getFAQs = async (term_id) => {
  const promise = axios.get(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/get_faqs/?term_id=${term_id}`
  );

  toast.promise(promise, {
    loading: "Processing",
    success: "",
    error: "Something went wrong",
  });

  try {
    const result = await promise;
    return result?.data;
  } catch (error) {
    console.error(error);
  }
};

const getAllPdfs = async (showToast = true) => {
  const promise = axios.get(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/get_all_pdfs`
  );

  if (showToast) {
    toast.promise(promise, {
      loading: "loading...",
      success: "Done",
      error: "Something went wrong",
    });
  }

  try {
    const result = await promise;
    return result?.data;
  } catch (error) {
    console.error(error);
  }
};

const processInteractivePdf = async (userInput) => {
  try {
    const result = await axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/interactive_document`,
      {
        ...userInput,
      }
    );
    return result?.data;
  } catch (error) {
    console.error(error);
  }
};

const processVideoHelper = async (userInput) => {
  try {
    const result = await axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/generate_video`,
      {
        ...userInput,
      }
    );
    return result?.data;
  } catch (error) {
    console.error(error);
  }
};

const getPdfData = async (resource_id) => {
  const promise = axios.post(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/get_resource`,
    {
      resource_id,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  toast.promise(promise, {
    loading: "Processing",
    error: "Something went wrong",
  });

  try {
    const result = await promise;
    return result?.data;
  } catch (error) {
    console.error(error);
  }
};

const getOutputs = async (resource_id) => {
  const promise = axios.get(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/get_outputs?resource_id=${resource_id}`
  );

  toast.promise(promise, {
    loading: "Processing",
    error: "Something went wrong",
  });

  try {
    const result = await promise;
    return result?.data;
  } catch (error) {
    console.error(error);
  }
};

const getOutputDetails = async (output_id) => {
  const promise = axios.get(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/get_output_details?output_id=${output_id}`
  );

  toast.promise(promise, {
    loading: "Processing",
    error: "Something went wrong",
  });

  try {
    const result = await promise;
    return result?.data;
  } catch (error) {
    console.error(error);
  }
};

const dateToString = (dateString) => {
  const dateObject = new Date(dateString);

  const formattedDate = dateObject.toLocaleDateString("en-US", {
    month: "short",
    day: "2-digit",
    year: "numeric",
  });

  const formattedTime = dateObject.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
  });

  const formattedDateTime = `${formattedDate} ${formattedTime}`;

  return formattedDateTime;
};

const getAllDomains = async () => {
  try {
    let res = await axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/get_all_domains`
    );
    return res.data;
  } catch (e) {
    toast.error("Something went wrong");
  }
};

function unsecuredCopyToClipboard(text) {
  console.log("unsecuredCopyToClipboard");
  const textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  try {
    document.execCommand("copy");
  } catch (err) {
    console.error("Unable to copy to clipboard", err);
  }
  document.body.removeChild(textArea);
}

const getMenuItems = async (text) => {
  try {
    let res = await axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/get_menu_items`,
      {
        content: text,
      }
    );
    return res.data;
  } catch (e) {
    // toast.error("Something went wrong");
  }
};

// function extractTextFromHTML(htmlString) {
//   const div = document.createElement("div");
//   div.innerHTML = htmlString;
//   const texts = Array.from(div.childNodes).map((node) => node.textContent);
//   return texts.join("\n");
// }

function extractTextFromHTML(htmlString) {
  const div = document.createElement("div");
  div.innerHTML = htmlString;
  const texts = Array.from(div.childNodes).map((node) => node.textContent);
  // for (let i = 0; i < texts.length - 1; i++) {
  //   const currentLine = texts[i];
  //   const nextLine = texts[i + 1];

  //   if (currentLine[currentLine.length - 1] === "-") {
  //     texts[i] = currentLine.slice(0, -1) + nextLine.trimLeft();
  //     texts.splice(i + 1, 1); // Remove the next line
  //     i--; // Decrement index to recheck the current line
  //   } else {
  //     texts[i] = currentLine + " "; // Add a space
  //   }
  // }
  console.log("NewValue : ", texts.join(" "));
  return texts.join(" ");
}

function getSelectionContent() {
  var html = "";
  if (typeof window.getSelection != "undefined") {
    var sel = window.getSelection();
    if (sel.rangeCount) {
      var container = document.createElement("div");
      for (var i = 0, len = sel.rangeCount; i < len; ++i) {
        container.appendChild(sel.getRangeAt(i).cloneContents());
      }
      html = container.innerHTML;
    }
  } else if (typeof document.selection != "undefined") {
    if (document.selection.type == "Text") {
      html = document.selection.createRange().htmlText;
    }
  }
  const textContent = extractTextFromHTML(html);
  return textContent;
}

// // bind events for selection

// document.addEventListener("mouseup", function () {
//   console.log("mouseup");
//   var selectedHTML = getSelectionContent();
//   if (selectedHTML) console.log(selectedHTML);
// });

// document.addEventListener("keyup", function (e) {
//   console.log("keyup");
//   var selectedHTML,
//     key = e.keyCode || e.which;
//   if (key === 16) {
//     // if "shift" key was released
//     selectedHTML = getSelectionContent();
//     if (selectedHTML) console.log(selectedHTML);
//   }
// });

export {
  downloadFile,
  processInput,
  handlePlugin,
  smartGenerate,
  generateVideo,
  findRectangleCorners,
  normalizePoint,
  // getArrowPoints,
  getToolMetadata,
  findArrowPoints,
  uploadImage,
  getPrompts,
  processUserQuery,
  getFAQs,
  getAllPdfs,
  processInteractivePdf,
  getPdfData,
  getOutputs,
  getOutputDetails,
  dateToString,
  getAllDomains,
  processSectionsList,
  unsecuredCopyToClipboard,
  processVideoHelper,
  getMenuItems,
  getSelectionContent,
};
