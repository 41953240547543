// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
// import { VideoPlayer } from "../../components";

// const VideoOutputPage = () => {
//   const [videoUrl, setVideoUrl] = useState("");
//   const { outputId } = useParams();
//   // useEffect(() => {
//   //   (async () => {
//   //     const result = await axios.get(
//   //       `${process.env.REACT_APP_BACKEND_BASE_URL}/get_video_url`,
//   //       {
//   //         outputId,
//   //       }
//   //     );
//   //     setVideoUrl(result?.data);
//   //   })();
//   // }, []);
//   return (
//     <div className="flex flex-col">
//       <h1 className="text-4xl text-center font-semibold text-gray-800 my-8">
//         Tirzepatide Once Weekly for the Treatment of Obesity
//       </h1>
//       <div className="flex justify-center">
//         {/* <video src={videoUrl} height={400} width={1200} controls /> */}
//         <VideoPlayer />
//       </div>
//     </div>
//   );
// };

// export { VideoOutputPage };

import React, { useEffect, useState } from "react";
import { VideoPlayer } from "../../components";
import { Chat } from "../../components/Chat/Chat";
import { useParams } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import { Button } from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
const data = {
  id: 2,
  title: "Tirzepatide Once Weekly for the Treatment of Obesity",
  resourceId: "6684e2c3602a7efb34e55832",
  videoUrl:
    "https://storage.googleapis.com/tsoai-resources-data/Tirzepatide_v1.mp4",
  thumbnailsUrl:
    "https://storage.googleapis.com/tsoai-resources-data/tirzepatide_thumbnails_1.vtt",
  tracks: [
    {
      src: "https://storage.googleapis.com/tsoai-resources-data/new_terzepatide_chapters_1.vtt",
      kind: "chapters",
      language: "en-US",
      default: true,
    },
  ],
};
const VideoOutputPage = () => {
  const { outputId } = useParams();

  const [currData, setCurrData] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/get_video`,
          {
            output_id: outputId,
          }
        );
        setCurrData(res?.data);
        // setCurrData(data);
      } catch (e) {
        toast.error("Something went wrong");
      }
    })();
  }, [outputId]);
  return (
    <div className="max-h-[100vh] ">
      {currData?.id && (
        <>
        <p className="text-4xl text-center font-semibold text-gray-800 my-8">
          {currData?.title} 
        </p>
        <Button  onClick={e=>navigate('/outputs/'+currData.resourceId)}>back</Button>&nbsp;
        <Button onClick={e=>navigate('/')}>Dashboard</Button>
        </>
      )}
      {currData?.id ? (
        <div className="flex justify-center gap-8 h-[95%]">
          <VideoPlayer
            videoUrl={currData?.videoUrl}
            resourceId={currData?.chatId}
            thumbnailsUrl={currData?.thumbnailsUrl}
            tracks={currData?.tracks}
            title={currData?.title}
          />
          {/* {currData?.hasChat && <Chat resourceId={currData?.chatId} />} */}
          <Chat resourceId={currData?.resourceId} />
        </div>
      ) : (
        <>
          <div className="flex flex-col justify-center items-center h-[100vh]">
            <p className="text-6xl text-center">404 Video not found</p>
          </div>
        </>
      )}
    </div>
  );
};

export { VideoOutputPage };
